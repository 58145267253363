<template>
	<body class="hold-transition sidebar-mini layout-fixed sidebar-closed sidebar-collapse">
		<div class="wrapper">
			<Navbar></Navbar>
			<Sidebar></Sidebar>
			<div class="content-wrapper">
				<ContentHeader :pagetitle="PageTitle"></ContentHeader>
				<section class="content">
					<div class="container-fluid">
						<Components></Components>
					</div>
				</section>
			</div>
			<Footer></Footer>
			<ControlSidebar></ControlSidebar>
		</div>
	</body>
</template>

<script>
import ContentHeader from "../../../template/ContentHeader.vue"
import Navbar from "../../../template/Navbar.vue";
import Sidebar from "../../../template/Sidebar.vue";
import Footer from "../../../template/Footer.vue";
import ControlSidebar from "../../../template/ControlSidebar.vue"
import Components from "./Components.vue"
import APIService from "../../../services/api.service"

export default {
	name: "App",
    data() {
        return {
            PageTitle: "Report Dashboard",
            data: [],
            module : this.$route.query.module,
            bodyFormData: new FormData()
        }
    },

    watch:{
        $route: {
            handler: function(newRouteValue){
                this.data = [];
                this.module = newRouteValue.query.module
                if(!this.module){
                    window.location.replace("/");
                }
            },
            deep: true
        }
    },
	components: {
		ContentHeader,
		Navbar,
		Sidebar,
		Footer,
		ControlSidebar,
        Components
	},
	methods: {
        async getDetails(){
            this.bodyFormData = new FormData();
            this.bodyFormData.append("code", this.module);
            await APIService.callAPI('modules/get-module-data', this.bodyFormData, function(response){
                if(response.data.success){
                    this.PageTitle = `${response.data.data.Module}`
                }
            }.bind(this));
        }
	},
	mounted() {
        this.getDetails();
	}
};
</script>