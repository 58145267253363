<template>
    <div class="card">
        <div class="card-header">
            <div class="card-tools">
                <div class="input-group input-group-sm">
                    <input type="text" @keydown.enter="search"  v-model="keyword" class="form-control float-right" placeholder="Search">
                    <div class="input-group-append">
                        <button type="submit" @click.prevent="search" class="btn btn-default">
                            <i class="fas fa-search"></i>
                        </button>
                        <button v-if="showReset" type="submit" @click.prevent="clear" class="btn btn-danger" >
                            <i class="fas fa-sync-alt"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body table-responsive p-0">
            <table class="table table-bordered text-nowrap">
                <tbody>
                    <tr v-for="(data, i) in data.data" :key="i">
                        <td>
                            <strong>{{ data.u_Title }}</strong><br />
                            {{ data.u_Body }}
                        </td>
                        <td style="width: 100px;">{{ getHumanDate(data.u_create_date) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div class="card-footer clearfix">
            {{ data.paginate.display_result }} 
            <div class="card-tools">
                <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page > 1" @click.prevent="paginate(1)">First</button>
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page > 1" @click.prevent="paginate(data.paginate.current_page - 1)">Prev</button>
                    </div>
                    <select class="custom-select" @change="paginate($event.target.value)">
                        <option :selected="page == data.paginate.current_page" v-for="(page, i) in data.paginate.pages" :key="i"   :value="page">
                            Page {{ page }}
                        </option>
                    </select>
                    <div class="input-group-append">
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page < data.paginate.total_page" @click.prevent="paginate(data.paginate.current_page + 1)">Next</button>
                        <button  type="submit" class="btn btn-default" v-if="data.paginate.current_page < data.paginate.total_page" @click.prevent="paginate(data.paginate.total_page)">Last</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import useValidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
    props: ['data'],
    data() {
        return {
            v$: useValidate(),
            ModalLabel: '',
            loading: false,
            submitButtonLabel : 'Save Changes',
            ModalShow: false,
            ModalReadOnly: false,
            showReset: this.data.searched_keyword? true : false,
            keyword : this.data.searched_keyword?? "",
            user_id : null,
            user : {
                last_name : '',
                first_name : '',
                middle_name : '',
                email: '',
                mobile_no: ''
            },
            bodyFormData : new FormData()
        }
    },
    methods: {
        getHumanDate(date) {
            return moment(date).fromNow();
        },
        search() {
            if(this.keyword){
                this.$parent.search(this.keyword);
            }
        },
        paginate(page){
            this.$parent.search(this.keyword, page);
        },
        clear() {
            this.keyword = "";
            this.$parent.search(this.keyword);
        }
    },
    mounted() {},
    validations() {
        return {
            user: {
                last_name: { required },
                first_name: { required },
                middle_name: { required },
                email: { email, required }
            },
        }
    }
}
</script>