<template>
	<body class="hold-transition sidebar-mini layout-fixed">
		<div class="wrapper">
			<Preloader v-if="isloading"></Preloader>
			<Navbar></Navbar>
			<Sidebar></Sidebar>
			<div class="content-wrapper">
				<ContentHeader pagetitle="Change Password" :navigation="navigation"></ContentHeader>
				<section class="content">
					<div class="container-fluid">
                        <Components></Components>
					</div>
				</section>
			</div>
			<Footer></Footer>
			<ControlSidebar></ControlSidebar>
		</div>
	</body>
</template>

<script>

import Preloader from "../../template/Preloader.vue"
import ContentHeader from "../../template/ContentHeader.vue"
import Navbar from "../../template/Navbar.vue";
import Sidebar from "../../template/Sidebar.vue";
import Footer from "../../template/Footer.vue";
import ControlSidebar from "../../template/ControlSidebar.vue"
import Components from "./Components.vue"


export default {
	name: "App",
	components: {
		Preloader,
		ContentHeader,
        Components,
		Navbar,
		Sidebar,
		Footer,
		ControlSidebar
	},
	data() {
		return {
			navigation : {
				"Home" : "/",
				"Change Password" : "#"
			},
			isloading : false
		}
	},
	methods: {
		setloading(loading){
			this.loading = loading;
		}
	},
	mounted() {
	}
};
</script>