<template>
    <div class="card">
        <div class="card-header">
            <div class="card-tools">
                <div class="input-group input-group-sm">
                    <input type="text" @keydown.enter="search"  v-model="keyword" class="form-control float-right" placeholder="Search">
                    <div class="input-group-append">
                        <button type="submit" @click.prevent="search" class="btn btn-default">
                            <i class="fas fa-search"></i>
                        </button>
                        <button v-if="keyword" type="submit" @click.prevent="clear" class="btn btn-danger" >
                            <i class="fas fa-sync-alt"></i>
                        </button>
                        <button type="submit" @click.prevent="Add" class="btn btn-info">
                            <i class="fas fa-plus"></i>
                        </button>
                        <button type="submit" @click.prevent="Export" class="btn btn-primary">
                            <i class="fas fa-download"></i>
                        </button>
                        <button type="submit" @click.prevent="showBatchModal" class="btn btn-warning">
                            <i class="fas fa-upload"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body table-responsive p-0">
            <Loading v-if="loading"></Loading>
            <table class="table table-bordered text-nowrap">
                <thead>
                    <tr>
                        <th v-for="(header, i) in data.header" :key="i">
                            {{ header }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(employee, i) in data.data" :key="i">
                        
                        <td>{{ employee.employee_number }}</td>
                        <td>{{ employee.first_name + " " + employee.last_name }}</td>
                        <td><label  v-if="employee.roles[0] == 'ADMIN'" class="badge bg-success">Admin</label> {{ employee.email }}</td>
                        <td>{{ employee.branch }}</td>
                        <td>
                            <div class="checkbox checbox-switch switch-primary">
                                <label>
                                    <input type="checkbox" name="" :checked="employee.Status"  @change="onStatusChange(employee.id, $event)"/>
                                    <span></span>                                        
                                </label>
                             </div>
                        </td>
                        <td>
                            <div class="btn-group btn-group-sm">
                                <button type="submit" @click.prevent="Edit(employee.id)" class="btn btn-info">
                                    <i class="fas fa-pen"></i>
                                </button>
                                <button type="submit" @click.prevent="View(employee.id)"  class="btn btn-primary">
                                    <i class="far fa-eye"></i>
                                </button>
                                <button  type="submit" @click.prevent="Remove(employee)" class="btn btn-danger" >
                                    <i class="fas fa-trash"></i>
                                </button>

                                <button v-if="employee.roles[0] != 'ADMIN'" class="btn btn-xs btn-warning" @click.prevent="setAdmin(employee)">Set as Admin</button>
                                <button v-if="employee.roles[0] == 'ADMIN'" class="btn btn-xs btn-secondary" @click.prevent="removeAdmin(employee)">Remove as Admin</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div class="card-footer clearfix"  v-if="data.data">
            {{ data.paginate.display_result?? "" }} 
            <div class="card-tools">
                <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page > 1" @click.prevent="paginate(1)">First</button>
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page > 1" @click.prevent="paginate(data.paginate.current_page - 1)">Prev</button>
                    </div>
                    <select class="custom-select" @change="paginate($event.target.value)">
                        <option :selected="page == data.paginate.current_page" v-for="(page, i) in data.paginate.pages" :key="i"   :value="page">
                            Page {{ page }}
                        </option>
                    </select>
                    <div class="input-group-append">
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page < data.paginate.total_page" @click.prevent="paginate(data.paginate.current_page + 1)">Next</button>
                        <button  type="submit" class="btn btn-default" v-if="data.paginate.current_page < data.paginate.total_page" @click.prevent="paginate(data.paginate.total_page)">Last</button>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div v-if="ModalShow" class="modal show" role="dialog"  style="display: block;">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ ModalLabel }}</h5>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="required">Employee No</label>
                        <input :disabled="ModalReadOnly" type="text" :class="v$.employee.employee_no.$error? 'form-control is-invalid' : 'form-control' " placeholder="Employee No." v-model="employee.employee_no">
                        <span class="error invalid-feedback" v-if="v$.employee.employee_no.$error">{{ v$.employee.employee_no.$errors[0].$message }} </span>
                    </div>
                    <div class="form-group">
                        <label class="required">First Name</label>
                        <input :disabled="ModalReadOnly" type="text" :class="v$.employee.first_name.$error? 'form-control is-invalid' : 'form-control' " placeholder="First Name" v-model="employee.first_name">
                        <span class="error invalid-feedback" v-if="v$.employee.first_name.$error">{{ v$.employee.first_name.$errors[0].$message }} </span>
                    </div>
                    <div class="form-group">
                        <label class="">Middle Name</label>
                        <input :disabled="ModalReadOnly" type="text" class="form-control" placeholder="Middle Name" v-model="employee.middle_name">
                    </div>
                    <div class="form-group">
                        <label class="required">Last Name</label>
                        <input :disabled="ModalReadOnly" type="text" :class="v$.employee.last_name.$error? 'form-control is-invalid' : 'form-control' " placeholder="Last Name" v-model="employee.last_name">
                        <span class="error invalid-feedback" v-if="v$.employee.last_name.$error">{{ v$.employee.last_name.$errors[0].$message }} </span>
                    </div>
                    <div class="form-group">
                        <label class="required">Position</label>
                        <input :disabled="ModalReadOnly" type="text" :class="v$.employee.position.$error? 'form-control is-invalid' : 'form-control' " placeholder="Position" v-model="employee.position">
                        <span class="error invalid-feedback" v-if="v$.employee.position.$error">{{ v$.employee.position.$errors[0].$message }} </span>
                    </div>
                    <div class="form-group">
                        <label class="required">Department</label>
                        <input :disabled="ModalReadOnly" type="text" :class="v$.employee.department.$error? 'form-control is-invalid' : 'form-control' " placeholder="Department" v-model="employee.department">
                        <span class="error invalid-feedback" v-if="v$.employee.department.$error">{{ v$.employee.department.$errors[0].$message }} </span>
                    </div>
                    <div class="form-group">
                        <label class="required">Gender</label>
                        <select v-model="employee.gender"  :class="v$.employee.gender.$error? 'custom-select is-invalid' : 'custom-select' " :disabled="ModalReadOnly" >
                            <option value="m" :selected="employee.gender == 'm'">Male</option>
                            <option value="f" :selected="employee.gender == 'f'">Female</option>
                        </select>
                        <span class="error invalid-feedback" v-if="v$.employee.gender.$error">{{ v$.employee.gender.$errors[0].$message }} </span>
                    </div>
                    <div class="form-group">
                        <label class="required">Email Address</label>
                        <input :disabled="ModalReadOnly" type="email" :class="v$.employee.email.$error? 'form-control is-invalid' : 'form-control' " placeholder="Email Address" v-model="employee.email">
                        <span class="error invalid-feedback" v-if="v$.employee.email.$error">{{ v$.employee.email.$errors[0].$message }} </span>
                    </div>
                    <div class="form-group">
                        <label class="required">Contact No</label>
                        <input :disabled="ModalReadOnly" type="tel" :class="v$.employee.contact.$error? 'form-control is-invalid' : 'form-control' " placeholder="Contact No" v-model="employee.contact">
                        <span class="error invalid-feedback" v-if="v$.employee.contact.$error">{{ v$.employee.contact.$errors[0].$message }} </span>
                    </div>
                    
                    <div class="form-group" v-if="branch.length && su">
                        <label class="required">Branch</label>
                        <div class="input-group">
                            <select class="custom-select" v-model="employee.branch" :disabled="ModalReadOnly" >
                                <option v-for="(item, i) in branch" :key="i" :value="item.branchId" :selected="employee.branch == item.branchId">
                                    {{ item.Branch }}
                                </option>
                            </select>
                            <div class="input-group-append" v-if="!ModalReadOnly">
                                <button class="btn btn-danger"  v-if="employee.branch && su" @click.prevent="removetoBranch(employee)">Remove to Branch</button>
                            </div>
                        </div>
                    </div>
                    
                    <small>All fields markes with an asterisk (<span class="required"></span> ) are required.</small>
                </div>
                <div class="modal-footer">
                    <button type="button" :disabled="loading"  class="btn btn-secondary" @click.prevent="hideModal" >Close</button>
                    <button type="button" v-if="!ModalReadOnly" :disabled="loading" class="btn btn-primary" @click.prevent="employee.id? submitUpdate() : submitAdd()  "> {{ submitButtonLabel }} </button>
                </div>
            </div>
        </div>
    </div>


    <div v-if="ModalBatchShow" class="modal show" role="dialog"  style="display: block;">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Batch Upload</h5>
                </div>
                <div class="modal-body">
                    
                    <div class="form-group" v-if="branch.length && su">
                        <label class="required">Branch</label>
                        <select class="custom-select" v-model="batch_upload.branch" :disabled="ModalReadOnly" >
                            <option v-for="(item, i) in branch" :key="i" :value="item.branchId">
                                {{ item.Branch }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group" v-if="!ModalReadOnly">
                        <label class="required">Upload Excel File</label>
                        <input :disabled="ModalReadOnly" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"  ref="file" :class="v$.batch_upload.file.$error? 'form-control is-invalid' : 'form-control' " placeholder="Upload Excel File" @change="UploadFile($event)">
                        <span class="error invalid-feedback" v-if="v$.batch_upload.file.$error">{{ v$.batch_upload.file.$errors[0].$message }} </span>
                    </div>
                    
                    <small>All fields markes with an asterisk (<span class="required"></span> ) are required.</small>
                </div>
                <div class="modal-footer">
                    <button type="button" :disabled="isLoading"  class="btn btn-secondary" @click.prevent="hideModal()" >Close</button>
                    <button type="button" :disabled="isLoading"  class="btn btn-info" @click.prevent="downloadTemplate()" >Download Template</button>
                    <button type="button" v-if="!ModalReadOnly" :disabled="isLoading" class="btn btn-primary" @click.prevent="batchUpload()">Upload</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import APIService from "../../services/api.service"
    import useValidate from '@vuelidate/core'
    import moment from 'moment'
    import Loading from "../../template/Loading.vue"
    import { required, email, helpers } from '@vuelidate/validators'
    import { toast } from 'vue3-toastify';
    import Swal from 'sweetalert2'

    export default {
        components: {
            Loading
        },
        data() {
            return {
                v$: useValidate(),
                ModalLabel: '',
                su : false,
                keyword: '',
                data: [],
                page: 1,
                loading: false,
                dataLoaded: false,
                submitButtonLabel : 'Save Changes',
                ModalShow: false,
                ModalBatchShow: false,
                ModalReadOnly: false,
                bodyFormData : new FormData(),
                uploading: false,
                branch: [],
                employee: {
                    id : null,
                    first_name: "",
                    middle_name: "",
                    last_name: "",
                    employee_no: "",
                    gender: "",
                    email: "",
                    contact: "",
                    branch: "",
                    position: "",
                    department: "",
                },
                batch_upload : {
                    branch: null,
                    file: null,
                    template : 'https://cdn.notisafe.ph/templates/employee-batch-template.xlsx'
                }
            }
        }, 
        methods: {
            paginate(page){
                this.page = page;
                this.search();
            },
            resetFields(){

            },
            getHumanDate(date) {
                return moment(date).format('LL');
            },
            showModal() {
                this.ModalShow = true;
            },
            showBatchModal() {
                this.getBranches();
                this.ModalBatchShow = true;
            },
            hideModal() {
                this.ModalShow = false;
                this.ModalBatchShow = false;
            },
            ModifyData() {
                this.ModalReadOnly = false;
            },
            clear() {
                this.keyword = "";
                this.search(this.keyword);
            },
            downloadTemplate() {
                window.location.href = this.batch_upload.template;
            },
            async batchUpload(){
                this.isLoading = true;
                this.v$.$validate()
                if (!this.v$.$error) {
                    this.bodyFormData = new FormData();
                    this.bodyFormData.append("branch", this.batch_upload.branch);
                    this.bodyFormData.append("file", this.batch_upload.file);
                    await APIService.callAPI('employee/batch-upload',  this.bodyFormData, function(response){
                        if(response.data.success){
                            toast.success(response.data.message);
                            this.isLoading = false;
                            this.ModalReadOnly = true;
                            this.hideModal();
                            this.getDetails();
                            this.getBranches();
                            this.getList();
                            this.getAccess();
                        } else {
                            toast.error(response.data.message);
                            window.location.href = response.data.data;
                            this.ModalReadOnly = false;
                            this.isLoading = false;
                            this.hideModal();
                            this.getDetails();
                        }
                    }.bind(this));

                } else {
                    toast.error('There are items that require your attention.')
                    this.isLoading = false;
                }
            },
            async UploadFile(event) {
                this.batch_upload.file = event.target.files[0];
            },
            async View(id) {
                this.employee.id = id;
                this.ModalReadOnly = true;
                this.ModalShow = true;
                this.ModalLabel = 'Modify Employee';
                this.employee.employee_no = 'Fetching data..';
                this.employee.first_name = 'Fetching data..';
                this.employee.middle_name = 'Fetching data..';
                this.employee.last_name = 'Fetching data..';
                this.employee.contact = 'Fetching data..';
                this.employee.email = 'Fetching data..';
                this.employee.gender = 'Fetching data..';
                this.employee.position = 'Fetching data..';
                this.employee.department = 'Fetching data..';

                this.bodyFormData = new FormData();
                this.bodyFormData.append("employee_id", id);
                await APIService.callAPI('employee/get-details', this.bodyFormData, function(response){
					if(response.data.success){
                        this.dataLoaded = true;
                        this.submitButtonLabel = "Save Changes";
                        this.employee.employee_no = response.data.data.employeeNumber;
                        this.employee.first_name = response.data.data.firstName;
                        this.employee.middle_name = response.data.data.middleName;
                        this.employee.last_name = response.data.data.lastName;
                        this.employee.contact = response.data.data.contactNumber;
                        this.employee.email = response.data.data.email;
                        this.employee.gender = response.data.data.gender;
                        this.employee.position = response.data.data.Position;
                        this.employee.department = response.data.data.Department;
                        this.employee.branch = response.data.data.branch? response.data.data.branch.id : null;
					}
				}.bind(this));
            },
            async Edit(id) {
                this.employee.id = id;
                this.ModalReadOnly = true;
                this.ModalShow = true;
                this.ModalLabel = 'Modify Employee';
                this.employee.employee_no = 'Fetching data..';
                this.employee.first_name = 'Fetching data..';
                this.employee.middle_name = 'Fetching data..';
                this.employee.last_name = 'Fetching data..';
                this.employee.contact = 'Fetching data..';
                this.employee.email = 'Fetching data..';
                this.employee.gender = 'Fetching data..';

                this.bodyFormData = new FormData();
                this.bodyFormData.append("employee_id", id);
                await APIService.callAPI('employee/get-details', this.bodyFormData, function(response){
					if(response.data.success){
                        this.dataLoaded = true;
                        this.ModalReadOnly = false;
                        this.submitButtonLabel = "Save Changes";

                        this.employee.employee_no = response.data.data.employeeNumber;
                        this.employee.first_name = response.data.data.firstName;
                        this.employee.middle_name = response.data.data.middleName;
                        this.employee.last_name = response.data.data.lastName;
                        this.employee.contact = response.data.data.contactNumber;
                        this.employee.email = response.data.data.email;
                        this.employee.gender = response.data.data.gender;
                        this.employee.position = response.data.data.Position;
                        this.employee.department = response.data.data.Department;
                        this.employee.branch = response.data.data.branch? response.data.data.branch.id : null;
					}
				}.bind(this));
            },
            Add() {
                this.getBranches();
                this.employee.id = null;
                this.ModalReadOnly = false;
                this.ModalShow = true;
                this.ModalLabel = 'Add Employee';
                this.submitButtonLabel = "Save Changes";
            },
            async getList() {
                this.resetFields();
                this.search();
            },
            async search() {
                this.loading = true;
                this.bodyFormData = new FormData();
                this.bodyFormData.append("search", this.keyword);
                this.bodyFormData.append("page", this.page);
                await APIService.callAPI('employee/list', this.bodyFormData, function(result) {
                    this.data = result.data;
                    this.loading = false;
                }.bind(this));
            },
            async getBranches() {
                this.branch = [];
                await APIService.callAPI('users/get-branches',  this.bodyFormData, function(response) {
                    if(response.data.success){
                        this.branch = response.data.data;
                    } 
                }.bind(this));
                
            },
            async onStatusChange(id, e) {
                let status = 0;
                if(e.target.checked){
                    status = 1;
                }

                this.bodyFormData = new FormData();
                this.bodyFormData.append("employee_id", id);
                this.bodyFormData.append("status", status);
                await APIService.callAPI('employee/set-status', this.bodyFormData, function(response){
					if(response.data.success){
						toast.success(response.data.message);
					}
				}.bind(this));
            },
            async getAccess(){
                await APIService.callAPI('auth/details', {}, function(response) {
                    this.su = response.data.data.userAccess;
                }.bind(this));
            },
            async submitAdd() {
                this.loading = true;
                this.submitButtonLabel = "Loading..";
                this.v$.$validate()
                if (!this.v$.$error) {
                    this.bodyFormData = new FormData();

                    this.bodyFormData.append("employee_no", this.employee.employee_no);
                    this.bodyFormData.append("first_name", this.employee.first_name);
                    this.bodyFormData.append("last_name", this.employee.last_name);
                    this.bodyFormData.append("middle_name", this.employee.middle_name);
                    this.bodyFormData.append("gender", this.employee.gender);
                    this.bodyFormData.append("email", this.employee.email);
                    this.bodyFormData.append("contact", this.employee.contact);
                    this.bodyFormData.append("branch", this.employee.branch);
                    this.bodyFormData.append("position", this.employee.position);
                    this.bodyFormData.append("department", this.employee.department);

                    await APIService.callAPI('employee/add',  this.bodyFormData, function(response){
                        if(response.data.success){
                            this.getBranches();
                            this.getList();
                            toast.success(response.data.message);
                            this.hideModal();
                        } else {
                            toast.error(response.data.message);
                            this.showModal();
                            this.loading = false;
                            this.submitButtonLabel = "Save Changes";
                        }
                    }.bind(this));

                } else {
                    toast.error('There are items that require your attention.')
                    this.loading = false;
                    this.submitButtonLabel = "Save Changes";
                }
            },
            async submitUpdate() {
                this.loading = true;
                this.submitButtonLabel = "Loading..";
                this.v$.$validate()
                if (!this.v$.$error) {
                    this.bodyFormData = new FormData();

                    this.bodyFormData.append("employee_id", this.employee.id);
                    this.bodyFormData.append("employee_no", this.employee.employee_no);
                    this.bodyFormData.append("first_name", this.employee.first_name);
                    this.bodyFormData.append("last_name", this.employee.last_name);
                    this.bodyFormData.append("middle_name", this.employee.middle_name);
                    this.bodyFormData.append("gender", this.employee.gender);
                    this.bodyFormData.append("email", this.employee.email);
                    this.bodyFormData.append("contact", this.employee.contact);
                    this.bodyFormData.append("branch", this.employee.branch);
                    this.bodyFormData.append("position", this.employee.position);
                    this.bodyFormData.append("department", this.employee.department);

                    await APIService.callAPI('employee/update-details',  this.bodyFormData, function(response){
                        if(response.data.success){
                            this.getBranches();
                            this.getList();
                            toast.success(response.data.message);
                            this.hideModal();
                        } else {
                            toast.error(response.data.message);
                            this.showModal();
                            this.loading = false;
                            this.submitButtonLabel = "Save Changes";
                        }
                    }.bind(this));

                } else {
                    toast.error('There are items that require your attention.')
                    this.loading = false;
                    this.submitButtonLabel = "Save Changes";
                }
            },

            async Remove(employee) {
                Swal.fire({
                    title: 'Remove Employee',
                    html: `Are you sure you want to remove <b>${employee.first_name + " " + employee.last_name}</b>?`,
                    showCancelButton: true,
                    confirmButtonText: 'Yes, remove employee',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.bodyFormData = new FormData();
                        this.bodyFormData.append("employee_id", employee.id);
                        await APIService.callAPI('employee/remove', this.bodyFormData, function(response){
                            if(response.data.success){
                                toast.success(response.data.message);
                                this.getBranches();
                                this.getList();
                                this.getAccess();
                            }
                        }.bind(this));
                    }
                })
            },
            async Export() {
                let instance = toast.info("Please wait while we generate your download");
                await APIService.callAPI('employee/export-all',  {}, function(response){
                    if(response.data.success){
                        window.open(response.data.file, '_blank');
                        instance.dimsiss();
                    } else {
                        toast.error(response.data.message);
                    }
				}.bind(this));
            },
            async setAdmin(employee) {
                Swal.fire({
                    title: 'Set Admin',
                    html: `Are you sure you want to set <b>${employee.first_name + " " + employee.last_name}</b> as admin?`,
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.bodyFormData = new FormData();
                        this.bodyFormData.append("employee_id", employee.id);
                        await APIService.callAPI('employee/set-admin', this.bodyFormData, function(response){
                            if(response.data.success){
                                toast.success(response.data.message);
                                this.getBranches();
                                this.getList();
                                this.getAccess();
                            }
                        }.bind(this));
                    }
                })

            },
            async removeAdmin(employee) {
                Swal.fire({
                    title: 'Remove Admin',
                    html: `Are you sure you want to remove <b>${employee.first_name + " " + employee.last_name}</b> as admin?`,
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.bodyFormData = new FormData();
                        this.bodyFormData.append("employee_id", employee.id);
                        await APIService.callAPI('employee/remove-admin', this.bodyFormData, function(response){
                            if(response.data.success){
                                toast.success(response.data.message);
                                this.getBranches();
                                this.getList();
                                this.getAccess();
                            }
                        }.bind(this));
                    }
                })
            },

            async removetoBranch(employee) {
                Swal.fire({
                    title: 'Remove to Branch',
                    html: `Are you sure you want to remove <b>${employee.first_name + " " + employee.last_name}</b> to Branch?`,
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.bodyFormData = new FormData();
                        this.bodyFormData.append("employee_id", employee.id);
                        await APIService.callAPI('employee/remove-to-branch', this.bodyFormData, function(response){
                            if(response.data.success){
                                toast.success(response.data.message);
                                this.getBranches();
                                this.getList();
                                this.getAccess();
                            }
                        }.bind(this));
                    }
                    this.ModalReadOnly = false;
                    this.ModalShow = false;
                })
            }
        },
        mounted() {
            this.getBranches();
            this.getList();
            this.getAccess();
        },
        validations() {


            if(this.ModalShow) {
                return {
                    employee: {
                        first_name: { required },
                        last_name: { required },
                        employee_no: { required },
                        gender: { required },
                        position: { required },
                        department: { required },
                        email: { required, email },
                        contact: { required }
                    }
                }
            }
            if(this.ModalBatchShow) {
                return {
                    batch_upload: {
                        file: {
                            FileRules: helpers.withMessage("File is required", () => {
                                return this.batch_upload.file? true: false;
                            })
                        }
                    }
                }
            }
        }
    }
</script>