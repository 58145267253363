<template>
	<body class="hold-transition sidebar-mini layout-fixed">
		<div class="wrapper">
			<Navbar></Navbar>
			<Sidebar></Sidebar>
			<div class="content-wrapper">
				<!-- <div class="dashboard alert alert-warning" role="alert">
					This is a warning alert—check it out!
				</div> -->
				<ContentHeader pagetitle="Dashboard"></ContentHeader>
				<section class="content">
					<div class="container-fluid">
						<Components></Components>
					</div>
				</section>
			</div>
			<Footer></Footer>
			<ControlSidebar></ControlSidebar>
		</div>
	</body>
</template>

<script>
import $ from 'jquery'
import ContentHeader from "../../template/ContentHeader.vue"
import Navbar from "../../template/Navbar.vue";
import Sidebar from "../../template/Sidebar.vue";
import Footer from "../../template/Footer.vue";
import ControlSidebar from "../../template/ControlSidebar.vue"
import Components from "./Components.vue"


export default {
	name: "App",
	components: {
		ContentHeader,
		Navbar,
		Sidebar,
		Footer,
		ControlSidebar,
        Components
	},
	methods: {
		initializeSelect2() {
			$(".select2").select2();
			$(".select2bs4").select2({
				theme: "bootstrap4",
			});
		},
	},
	mounted() {
		this.initializeSelect2()
	}
};
</script>