<template>
  <footer class="main-footer">
    <strong>Copyright &copy; 2023</strong>
    All rights reserved.
    <div class="float-right d-none d-sm-inline-block">
      <b>Version</b> 1.0.0
    </div>
  </footer>
</template>

<script>
export default {};
</script>