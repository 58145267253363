<template>
    <div class="card">
        <div class="card-header">
            <div class="card-tools">
                <div class="input-group input-group-sm">
                    <input type="text" @keydown.enter="search"  v-model="keyword" class="form-control float-right" placeholder="Search">
                    <div class="input-group-append">
                        <button type="submit" @click.prevent="search" class="btn btn-default">
                            <i class="fas fa-search"></i>
                        </button>
                        <button v-if="keyword" type="submit" @click.prevent="clear" class="btn btn-danger" >
                            <i class="fas fa-sync-alt"></i>
                        </button>
                        <button type="submit" @click.prevent="Add" class="btn btn-info">
                            <i class="fas fa-plus"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body table-responsive p-0">
            <Loading v-if="isLoading"></Loading>
            <table class="table table-bordered text-nowrap">
                <thead>
                    <tr>
                        <th v-for="(header, i) in data.header" :key="i">
                            {{ header }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in data.data" :key="i">
                        <td>{{ item.Value }}</td>
                        <td>
                            <div class="btn-group btn-group-sm" v-if="item.CanDelete">
                                <button type="submit" @click.prevent="Edit(item)" class="btn btn-info">
                                    <i class="fas fa-pen"></i>
                                </button>
                                <button  type="submit" class="btn btn-danger" @click.prevent="Remove(item)"  >
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div class="card-footer clearfix"  v-if="data.data">
            {{ data.paginate.display_result?? "" }} 
            <div class="card-tools">
                <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page > 1" @click.prevent="paginate(1)">First</button>
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page > 1" @click.prevent="paginate(data.paginate.current_page - 1)">Prev</button>
                    </div>
                    <select class="custom-select" @change="paginate($event.target.value)">
                        <option :selected="page == data.paginate.current_page" v-for="(page, i) in data.paginate.pages" :key="i"   :value="page">
                            Page {{ page }}
                        </option>
                    </select>
                    <div class="input-group-append">
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page < data.paginate.total_page" @click.prevent="paginate(data.paginate.current_page + 1)">Next</button>
                        <button  type="submit" class="btn btn-default" v-if="data.paginate.current_page < data.paginate.total_page" @click.prevent="paginate(data.paginate.total_page)">Last</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="ModalShow" class="modal show" role="dialog"  style="display: block;">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ ModalLabel }}</h5>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="required">Field Value</label>
                        <input :disabled="ModalReadOnly" type="text" :class="v$.field.value.$error? 'form-control is-invalid' : 'form-control' " placeholder="Field Value" v-model="field.value">
                        <span class="error invalid-feedback" v-if="v$.field.value.$error">{{ v$.field.value.$errors[0].$message }} </span>
                    </div>
                    <small>All fields markes with an asterisk (<span class="required"></span> ) are required.</small>
                </div>
                <div class="modal-footer">
                    <button type="button" :disabled="isLoading"  class="btn btn-secondary" @click.prevent="hideModal" >Close</button>
                    <button type="button" v-if="!ModalReadOnly" :disabled="isLoading" class="btn btn-primary" @click.prevent="field.id? submitUpdate() : submitAdd()  "> {{ submitButtonLabel }} </button>
                </div>
            </div>
        </div>
    </div>
    


</template>

<script>
    import useValidate from '@vuelidate/core'
    import Loading from "../../../template/Loading.vue"
    import APIService from "../../../services/api.service"
    import { required } from '@vuelidate/validators'
    import { toast } from 'vue3-toastify';
    import Swal from 'sweetalert2'

    export default {
        components: {
            Loading
        },
        data() {
            return {
                v$: useValidate(),
                module_id : this.$route.query.module_id,
                field_id : this.$route.query.field_id,
                isLoading: false,
                data: [],
                keyword: "",
                page: 1,
                ModalLabel: "New field value",
                submitButtonLabel: "Save Changes",
                ModalReadOnly: true,
                ModalShow: false,
                field: {
                    id: null,
                    value: ""
                }
            }
        },
        watch:{
            $route: {
                handler: function(newRouteValue){
                    this.data = [];
                    this.module_id = newRouteValue.query.module_id
                    this.field_id = newRouteValue.query.field_id
                    if(!this.field_id || !this.module_id){
                        window.location.replace("/");
                    }
                    this.GetModuleDetails();
                },
                deep: true
            }
        },
        methods: {
            search() {
                this.data = [];
                this.GetModuleDetails();
            },
            paginate(page){
                this.page = page;
                this.GetModuleDetails();
            },
            Add() {
                this.field.id = null;
                this.ModalReadOnly = false;
                this.ModalShow = true;
                this.ModalLabel = 'New Field Value';
                this.submitButtonLabel = "Save Changes";
            },
            hideModal() {
                this.ModalShow = false;
            },
            clear() {
                this.v$.$reset();
                this.field.id = null;
                this.field.value = "";
                this.data = [];
                this.keyword = "";
                this.GetModuleDetails();
            },
            async GetModuleDetails(){
                this.isLoading = true;
                this.$parent.setTitle(`Loading..`);
                this.bodyFormData = new FormData();
                this.bodyFormData.append("field_id", this.field_id);
                this.bodyFormData.append("module_id", this.module_id);
                await APIService.callAPI('modules/get-details', this.bodyFormData, function(response){
                    if(response.data.success){
                        this.$parent.setTitle(`${response.data.details.field.Label}`);
                        this.GetModuleList();
                    }
                }.bind(this));
            },
            async GetModuleList(){
                this.isLoading = true;
                this.data = [];
                this.bodyFormData = new FormData();
                this.bodyFormData.append("search", this.keyword);
                this.bodyFormData.append("field_id", this.field_id);
                this.bodyFormData.append("module_id", this.module_id);
                this.bodyFormData.append("page", this.page);
                await APIService.callAPI('modules/field-values/list', this.bodyFormData, function(result) {
                    this.data = result.data;
                    this.isLoading = false;
                }.bind(this));
            },
            async submitAdd() {
                this.isLoading = true;
                this.submitButtonLabel = "Loading..";
                this.v$.$validate()
                if (!this.v$.$error) {
                    this.bodyFormData = new FormData();

                    this.bodyFormData.append("field_id", this.field_id);
                    this.bodyFormData.append("module_id", this.module_id);
                    this.bodyFormData.append("value", this.field.value);

                    await APIService.callAPI('modules/field-values/add',  this.bodyFormData, function(response){
                        if(response.data.success){
                            toast.success(response.data.message);
                            this.clear();
                            this.hideModal();
                        } else {
                            toast.error(response.data.message);
                            this.showModal();
                            this.isLoading = false;
                            this.submitButtonLabel = "Save Changes";
                        }
                    }.bind(this));

                } else {
                    toast.error('There are items that require your attention.')
                    this.loading = false;
                    this.submitButtonLabel = "Save Changes";
                }
            },
            async submitUpdate() {
                this.isLoading = true;
                this.submitButtonLabel = "Loading..";
                this.v$.$validate()
                if (!this.v$.$error) {
                    this.bodyFormData = new FormData();

                    this.bodyFormData.append("id", this.field.id);
                    this.bodyFormData.append("field_id", this.field_id);
                    this.bodyFormData.append("module_id", this.module_id);
                    this.bodyFormData.append("value", this.field.value);
                    await APIService.callAPI('modules/field-values/update',  this.bodyFormData, function(response){
                        if(response.data.success){
                            toast.success(response.data.message);
                            this.clear();
                            this.hideModal();
                        } else {
                            toast.error(response.data.message);
                            this.isLoading = false;
                            this.submitButtonLabel = "Save Changes";
                        }
                    }.bind(this));

                } else {
                    toast.error('There are items that require your attention.')
                    this.loading = false;
                    this.submitButtonLabel = "Save Changes";
                }
            },
            async Remove(item) {
                Swal.fire({
                    title: 'Remove Item',
                    html: `Are you sure you want to remove <b>${item.Value}</b>?`,
                    showCancelButton: true,
                    confirmButtonText: 'Yes, remove value',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.bodyFormData = new FormData();
                        this.bodyFormData.append("id", item.id);
                        await APIService.callAPI('modules/field-values/remove', this.bodyFormData, function(response){
                            if(response.data.success){
                                toast.success(response.data.message);
                                this.clear();
                                this.hideModal();
                            }
                        }.bind(this));
                    }
                })
            },
            async Edit(item) {
                this.field.id = item.id;
                this.ModalReadOnly = true;
                this.ModalShow = true;
                this.ModalLabel = 'Modify Field Value';
                this.field.value = 'Fetching data..';

                this.bodyFormData = new FormData();
                this.bodyFormData.append("id", item.id);
                await APIService.callAPI('modules/field-values/get', this.bodyFormData, function(response){
					if(response.data.success){
                        this.dataLoaded = true;
                        this.ModalReadOnly = false;
                        this.submitButtonLabel = "Save Changes";
                        this.field.value = response.data.data.Value;
					}
				}.bind(this));
            },
        },
        mounted() {
            if(!this.field_id || !this.module_id){
                window.location.replace("/");
            }
            this.GetModuleDetails();
        },
        validations() {
            return {
                field: {
                    value: { required }
                },
            }
        }
    }
</script>