<template>
    <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fas fa-times"></span>
        </button>
        <h5 class="modal-title" id="rightModalLabel">
            {{ details.ReportNumber  }}
        </h5>
    </div>
    <div class="modal-body">
        <div class="container">
            <Loading v-if="loading"></Loading>
            <p>
                <strong>Date Reported:</strong> {{ getHumanDate(details.SubmittedDate) }}<br />
                <strong>Date Observed:</strong> {{ details.ObservedDate }} <br />
                <strong>Time Observed:</strong> {{ details.ObservedTime }} <br />
                <strong>Risk Category:</strong> <span :class="details.getRiskClass">{{ details.Risk }}</span><br />
                <strong>Report Status:</strong> {{ details.Status }}<br />
            </p>
            <p>
                <strong>Reporter:</strong> <br />
                <ul class="list-unstyled">
                    <li>{{ reporter.Name }}</li>
                    <li>{{ reporter.EmployeeNumber }}</li>
                    <li>{{ reporter.Company }} <span v-if="reporter.Branch"> / {{ reporter.Branch }}</span></li>
                    
                </ul>
            </p>
            <p v-for="(item, i) in form" :key="i">
                <strong>{{ item.Field.Label }} : </strong>
                <ul class="list-unstyled">
                    <li>
                        <div v-show="item.Field.Type == 'SELECT'" v-for="(data, i) in item.Field.Value" :key="i">
                            <span v-if="data.selected">{{ data.Label }}</span>
                            <a  href="#" @click.prevent="EditForm(item)" v-if="data.selected && status_id == 1 || status_id == 3 || status_id == 4" >
                                &nbsp; <i class="fas fa-pen"></i>
                            </a>
                        </div>
                        <span v-if="item.Field.Type == 'DATE'">
                            {{ getHumanDateOnly(item.Field.Value) }}
                            <a href="#" @click.prevent="EditForm(item)" v-if="status_id == 1 || status_id == 3 || status_id == 4">
                                &nbsp; <i class="fas fa-pen"></i>
                            </a>
                        </span>
                        <span v-if="item.Field.Type == 'TIME'">
                            {{ getHumanTimeOnly(item.Field.Value) }}
                            <a href="#" @click.prevent="EditForm(item)" v-if="status_id == 1 || status_id == 3 || status_id == 4" >
                                &nbsp; <i class="fas fa-pen"></i>
                            </a>
                        </span>
                        <span v-if="item.Field.Type == 'DATETIME'">
                            {{ getHumanDate(item.Field.Value) }}
                            <a href="#" @click.prevent="EditForm(item)" v-if="status_id == 1 || status_id == 3 || status_id == 4" >
                                &nbsp; <i class="fas fa-pen"></i>
                            </a>
                        </span>
                        <span v-if="item.Field.Type == 'NUMBER'">
                            {{ formatNumber(item.Field.Value) }}
                            <a href="#" @click.prevent="EditForm(item)" v-if="status_id == 1 || status_id == 3 || status_id == 4" >
                                &nbsp; <i class="fas fa-pen"></i>
                            </a>
                        </span>
                        <span v-if="item.Field.Type == 'TEXT' || item.Field.Type == 'MULTI_TEXT'" >
                            {{ item.Field.Value }}
                            <a href="#" @click.prevent="EditForm(item)" v-if="status_id == 1 || status_id == 3 || status_id == 4">
                                &nbsp; <i class="fas fa-pen"></i>
                            </a>
                        </span>
                        <span class="gallery"  v-if="item.Field.Type == 'MEDIA_PHOTO_VIDEO'">
                            <div v-for="(data, i) in ViewMedia(item.Field.Value)" :key="i">
                                <img v-if="get_url_extension(data) == 'png' || get_url_extension(data) == 'jpeg' || get_url_extension(data) == 'jpg'" @click="Mappreview(data)" :src="data" alt="" class="img-attachment-preview">
                                <a v-if="get_url_extension(data) == 'avi' || get_url_extension(data) == 'mp4' || get_url_extension(data) == 'mpeg' || get_url_extension(data) == 'mov' || get_url_extension(data) == 'm4v'" :href="data" target="_blank" rel="noopener noreferrer">Download Video</a>
                            </div>
                        </span>
                    </li>
                </ul>
            </p>
            <p>
                <strong>Layout: </strong><br />
                <span v-for="(item, i) in blueprint.Branch" :key="i">
                    <span v-if="item.selected">{{ item.Label }} /</span>
                </span>
                <span v-for="(item, i) in blueprint.Location" :key="i">
                    <span v-if="item.selected">{{ item.Label }} /</span>
                </span>
                <span v-for="(item, i) in blueprint.Area" :key="i">
                    <span v-if="item.selected">{{ item.Label }} /</span>
                </span>
                <span v-for="(item, i) in blueprint.DetailedLocation" :key="i">
                    <span v-if="item.selected">{{ item.Label }}</span>
                </span><br />
                <img @click="Mappreview(blueprint.Map)" :src="blueprint.Map" alt="" class="img-preview">
            </p>

            <p>
                <strong>Respondents: </strong>
                <Autocomplete
                    placeholder="Search and add respondent"
                    id="RespondentAutoComplete"
                    @input="updateRespondentItems"
                    @onSelect="SelectRespondent"
                    :display-item="DisplayItem"
                    :use-html-for-results=true
                    :results="respondent_list"
                ></Autocomplete>
            
                <br />
                <ol>
                    <li v-for="(item, i) in respondents" :key="i">
                        {{ item.Name }}
                        <a href="#" @click.prevent="RemoveRespondent(item)" class="cursor-pointer">
                            &nbsp; <i class="fas fa-times text-danger"></i>
                        </a>
                        <ul v-if="item.Remarks" class="list-unstyled">
                            <li>
                                Remarks : {{ item.Remarks }}
                            </li>
                        </ul>

                    </li>
                </ol>
            
            </p>
            <p>
                <strong>Watchers: </strong>

                <Autocomplete
                    placeholder="Search and add watcher"
                    id="WatcherAutoComplete"
                    @input="updateWatcherItems"
                    @onSelect="SelectWatcher"
                    :display-item="DisplayItem"
                    :use-html-for-results=true
                    :results="watcher_list"
                ></Autocomplete>
                <br />
                <ol>
                    <li v-for="(item, i) in watchers" :key="i">
                        {{ item.Name }}
                        <a href="#" @click.prevent="RemoveWatcher(item)" class="cursor-pointer">
                            &nbsp; <i class="fas fa-times text-danger"></i>
                        </a>
                    </li>
                </ol>
            </p>

            <p>
                <strong>History: </strong>
                <ul>
                    <li v-show="!ShowAll" v-for="(item, i) in history.slice(0, 1)" :key="i" >
                        <i>{{ getHumanDate(item.Date )}}</i> : <span v-html="removePeriod(item.Content)"></span> - <strong>{{ item.Name }}</strong>
                    </li>
                    <li v-show="ShowAll" v-for="(item, i) in history" :key="i" >
                        <i>{{ getHumanDate(item.Date )}}</i> : <span v-html="removePeriod(item.Content)"></span> - <strong>{{ item.Name }}</strong>
                    </li>
                    <a href="#" v-if="!ShowAll" @click.prevent="ShowAllHistory">Show All</a>
                    <a href="#" v-if="ShowAll" @click.prevent="ShowLessHistory">Show Less</a>
                </ul>
            </p>
        </div>
    </div>

    <div class="modal-footer" v-if="status_id == 1">
        <button type="button" :disabled="loading" v-if="respondents.length > 0" class="btn btn-success" @click.prevent="ApproveReport()" >Approve</button>
        <button type="button" :disabled="loading" class="btn btn-danger" @click.prevent="RejectReport()" >Reject</button>
        <button type="button" :disabled="loading" class="btn btn-secondary" data-dismiss="modal" >Close</button>
    </div>
    <div class="modal-footer" v-if="status_id == 2 || status_id == 4 || status_id == 6 || status_id == 7">
        <button type="button" :disabled="loading" class="btn btn-secondary" data-dismiss="modal">Close</button>
    </div>
</template>

<script>
    import APIService from "../../../services/api.service"
    import Loading from "../../../template/Loading.vue"
    import Swal from 'sweetalert2'
    import { toast } from 'vue3-toastify';
    import moment from 'moment'
    import Autocomplete from 'vue3-autocomplete'
    import {preview} from 'vue3-image-preview';

    export default {
        components: {
            Loading,
            Autocomplete
        },  
        props: ['id'],
        data() {
            return {
                loading: true,
                respondent_list: [],
                watcher_list: [],
                details: [],
                reporter: [],
                blueprint: [],
                form: [],
                history: [],
                watchers: [],
                respondents: [],
                commentInput: "",
                comments: [],
                status_id: null,
                bodyFormData: null,
                report_id: null,
                selected_respondent: null,
                ShowAll: false
            }
        },  
        watch: { 
            id: function(newVal) { // watch it
                this.report_id = newVal
                this.getReportDetails(newVal);
            }
        },
        methods: {
            ShowLessHistory(){
                this.ShowAll = false;
            },
            ShowAllHistory(){
                this.ShowAll = true;
            },
            getHumanDate(date) {
                return moment(date).format('LLLL');
            },
            getDate(date) {
                return moment(date).format('LL');
            },
            getTime(date) {
                return moment(date).format('LTS');
            },
            removePeriod(text){
                return text.replace(/\.$/, "");
            },
            getLabel (item) {
                return item.name
            },
            DisplayItem(item){
                return item.first_name;
            },
            ViewMedia(JSONitem) {
                JSONitem = JSON.parse(JSONitem);
                return JSONitem;
            },
            Mappreview: function(image) {
                preview({
                    images: image
                });
            },
            async SelectRespondent(employee) {
                const { value: text,  isConfirmed: isConfirmed} = await Swal.fire({
                    title: `${employee.first_name} ${employee.last_name} as respondent`,
                    showCancelButton: true,
                    confirmButtonText: 'Add Respondent',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    input: "textarea",
                    inputLabel: "",
                    inputPlaceholder: "Add Remarks (if any)",
                    inputAttributes: {
                        "aria-label": "Remarks"
                    }
                });

                if(isConfirmed){
                    this.$parent.AddRespondent(employee.id, text);
                }

            },
            async SelectWatcher(employee) {
                const { value: text,  isConfirmed: isConfirmed} = await Swal.fire({
                    title: `${employee.first_name} ${employee.last_name} as watcher`,
                    showCancelButton: true,
                    confirmButtonText: 'Add Watcher',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                });

                if(isConfirmed){
                    this.$parent.AddWatcher(employee.id, text);
                }

            },
            async updateRespondentItems (text) {
                this.bodyFormData = new FormData();
                this.bodyFormData.append("search", text);
                this.bodyFormData.append("page", 1);
                await APIService.callAPI('employee/list', this.bodyFormData, function(result) {
                    this.respondent_list = result.data.data;
                }.bind(this));
            },
            async updateWatcherItems (text) {
                this.bodyFormData = new FormData();
                this.bodyFormData.append("search", text);
                this.bodyFormData.append("page", 1);
                await APIService.callAPI('employee/list', this.bodyFormData, function(result) {
                    this.watcher_list = result.data.data;
                }.bind(this));
            },
            async EditForm(item){
                switch (item.Field.Type) {
                    case "SELECT":
                        var select_items = [];
                        for (const listelement of item.Field.Value) {
                            select_items[listelement.Id] = listelement.Label;
                        }
                        this.SWAL_SELECT(item.Field.Id, item.Field.Label, select_items);
                        break;
                    case "TEXT":
                        this.SWAL_GENERIC(item.Field.Id, item.Field.Label, "text", item.Field.Value);
                        break;
                    case "MULTI_TEXT":
                        this.SWAL_GENERIC(item.Field.Id, item.Field.Label, "textarea", item.Field.Value);
                        break;
                    case "NUMBER":
                        this.SWAL_GENERIC(item.Field.Id, item.Field.Label, "number", item.Field.Value);
                        break;
                    default:
                        this.SWAL_GENERIC(item.Field.Id, item.Field.Label, "text", item.Field.Value);
                        break;
                }
                
            },
            async SWAL_SELECT(fieldId, label, options){
                const { value: value } =  await Swal.fire({
                    title: `Update ${label}`,
                    input: "select",
                    inputOptions: options,
                    inputPlaceholder: `Select ${label}`,
                    confirmButtonText: 'Update',
                    confirmButtonColor: '#28a745',
                    cancelButtonColor: '#3085d6',
                    showCancelButton: true,
                    inputValidator: (value) => {
                        if (!value) {
                            return `You need to select ${label}.`;
                        }
                    }
                });
                if (value) {
                    this.UpdateForm(fieldId, value);
                }
            },
            async SWAL_GENERIC(fieldId, label, type, inputValue){
                const { value: value } =  await Swal.fire({
                    title: `${label}`,
                    input: type,
                    inputValue,  
                    confirmButtonText: 'Update',
                    confirmButtonColor: '#28a745',
                    cancelButtonColor: '#3085d6',
                    showCancelButton: true,
                    inputValidator: (value) => {
                        if (!value) {
                            return `${label} is required.`;
                        }
                    }
                });
                if (value) {
                    this.UpdateForm(fieldId, value);
                }
            },
            async UpdateForm(FieldID, Value){
                this.loading = true;
                this.bodyFormData = new FormData();
                this.bodyFormData.append("report_id", this.report_id);
                this.bodyFormData.append("field_id", FieldID);
                this.bodyFormData.append("value", Value);
                await APIService.callAPI('report/update-form', this.bodyFormData, function(result) {
                    if(result.data.success){
                        toast.success(result.data.message);
                        this.getReportDetails(this.report_id);
                    } else {
                        toast.error(result.data.message);
                        this.showModal = false;
                    }
                    this.ReadOnly = true;
                    this.loading = false;
                }.bind(this));
            },
            get_url_extension(url) {
                return url.split('.').pop().trim().toLowerCase();
            },
            async getReportDetails(id){
                this.details = [];
                this.reporter = [];
                this.blueprint = [];
                this.history = [];
                this.watchers = [];
                this.respondents = [];
                this.form = [];
                this.status_id = null;

                this.bodyFormData = new FormData();
                this.bodyFormData.append("report_id", id);
                await APIService.callAPI('report/get', this.bodyFormData, function(result) {
                    if(result.data.success){
                        this.status_id = result.data.details.StatusID;
                        this.details = result.data.details;
                        this.reporter = result.data.reporter;
                        this.blueprint = result.data.blueprint;
                        this.history = result.data.history;
                        this.watchers = result.data.watcher;
                        this.respondents = result.data.respondent;
                        this.form = result.data.forms;
                    }
                    this.loading = false;
                }.bind(this));
            },

                async RemoveRespondent(employee){
                Swal.fire({
                    title: 'Remove Respondent',
                    html: `Are you sure you want to remove <b>${employee.Name}</b> as respondent?`,
                    showCancelButton: true,
                    confirmButtonText: 'Yes, remove respondent',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.loading = true;
                        this.bodyFormData = new FormData();
                        this.bodyFormData.append("report_id", this.report_id);
                        this.bodyFormData.append("respondent", employee.ID);
                        await APIService.callAPI('report/remove-respondent', this.bodyFormData, function(result) {
                            if(result.data.success){
                                toast.success(result.data.message);
                                this.getReportDetails(this.report_id);
                            } else {
                                toast.error(result.data.message);
                                this.showModal = false;
                            }
                            this.ReadOnly = true;
                            this.loading = false;
                        }.bind(this));
                    }
                });

            },
            async RemoveWatcher(employee){
                Swal.fire({
                    title: 'Remove Watcher',
                    html: `Are you sure you want to remove <b>${employee.Name}</b> as watcher?`,
                    showCancelButton: true,
                    confirmButtonText: 'Yes, remove watcher',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.loading = true;
                        this.bodyFormData = new FormData();
                        this.bodyFormData.append("report_id", this.report_id);
                        this.bodyFormData.append("watcher", employee.ID);
                        await APIService.callAPI('report/remove-watcher', this.bodyFormData, function(result) {
                            if(result.data.success){
                                toast.success(result.data.message);
                                this.getReportDetails(this.report_id);
                            } else {
                                toast.error(result.data.message);
                            }
                            this.ReadOnly = true;
                            this.loading = false;
                        }.bind(this));
                    }
                });

            },

            async ApproveReport(){
                const { value: risk, isConfirmed: isConfirmed } = await Swal.fire({
                    title: "Approve Report",
                    html: `Are you sure you want to approve this report?`,
                    input: "select",
                    inputOptions: {
                        1: "High Risk",
                        2: "Medium Risk",
                        3: "Low Risk"
                    },
                    inputPlaceholder: "Select Risk Category",
                    confirmButtonText: 'Approve',
                    confirmButtonColor: '#28a745',
                    cancelButtonColor: '#3085d6',
                    showCancelButton: true,
                    inputValidator: (value) => {
                        if (!value) {
                            return "You need to select risk category.";
                        }
                    }
                });
                if (risk && isConfirmed) {
                    this.loading = true;
                    this.bodyFormData = new FormData();
                    this.bodyFormData.append("report_id", this.report_id);
                    this.bodyFormData.append("risk", risk);
                    await APIService.callAPI('report/approve', this.bodyFormData, function(result) {
                        if(result.data.success){
                            toast.success(result.data.message);
                            this.getReportDetails(this.report_id);
                            this.getComments();
                        } else {
                            toast.error(result.data.message);
                            this.showModal = false;
                        }
                        this.ReadOnly = true;
                        this.loading = false;
                    }.bind(this));
                }
            },
        },
        mounted() {
            
        },
    }
</script>