<template>
    <div class="card card-default">
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="required">Old Password</label>
                        <input type="password" :class="v$.password.old.$error? 'form-control is-invalid' : 'form-control' " placeholder="Old Password" v-model="password.old">
                        <span class="error invalid-feedback" v-if="v$.password.old.$error">{{ v$.password.old.$errors[0].$message }} </span>
                    </div>
                    <div class="form-group">
                        <label class="required">New Password</label>
                        <input type="password" :class="v$.password.new.$error? 'form-control is-invalid' : 'form-control' " placeholder="New Password" v-model="password.new">
                        <span class="error invalid-feedback" v-if="v$.password.new.$error">{{ v$.password.new.$errors[0].$message }} </span>
                    </div>
                    <div class="form-group">
                        <label class="required">Confirm Password</label>
                        <input type="password" :class="v$.password.confirm.$error? 'form-control is-invalid' : 'form-control' "  placeholder="Confirm Password" v-model="password.confirm">
                        <span class="error invalid-feedback" v-if="v$.password.confirm.$error">{{ v$.password.confirm.$errors[0].$message }} </span>
                    </div>
                    <small>All fields markes with an asterisk (<span class="required"></span> ) are required.</small>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <button type="submit" class="btn btn-primary" :disabled="loading"  @click="submitForm"> {{ buttonlabel }}</button>
        </div>
    </div>  
</template>

<script>
import { toast } from 'vue3-toastify';
import useValidate from '@vuelidate/core'
import APIService from "../../services/api.service"
import AuthService from "../../services/auth.service"
import { required, sameAs, minLength } from '@vuelidate/validators'
export default {
    data() {
        return {
            v$: useValidate(),
            password : {
                old : '',
                new : '',
                confirm: ''
            },
            loading: false,
            bodyFormData : new FormData(),
            buttonlabel: "Save Changes"
        }
    },
    methods: {
        async submitForm() {
            this.$parent.setloading(true);
            this.buttonlabel = "Updating Account..."
            this.loading =  true;
            this.v$.$validate()
            if (!this.v$.$error) {
                this.bodyFormData.append("old", this.password.old);
                this.bodyFormData.append("new", this.password.new);
                await APIService.callAPI('update-password', this.bodyFormData, function(response){
                    if(response.data.success){
                        toast.success(response.data.message);
                        AuthService.logout();
                        location.reload();
                    } else {
                        toast.error(response.data.message);
                    }
                }.bind(this));
            } else {
                toast.error('There are items that require your attention.')
            }
            this.loading =  false;
        }
    },
    validations() {
        return {
            password: {
                old: { required },
                new: { 
                    required,
                    minLength: minLength(8)
                },
                confirm: { required, sameAsPassword: sameAs(this.password.new) },
            },
        }
    }
}
</script>