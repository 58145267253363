<template>
   
    <div class="card">
        <div class="card-body">
            <div class="form-group" v-if="ModalReadOnly">
                <label class="required">Company Logo</label><br />
                <img :src="details.logo" width="100"/>
            </div>
            <div class="form-group" v-if="ModalReadOnly">
                <label class="required">Company Background</label>
                <p>{{ details.description }}</p>
            </div>
            <div class="form-group" v-if="!ModalReadOnly">
                <label class="required">Upload Logo</label>
                <input :disabled="ModalReadOnly" type="file"  accept="image/*" ref="file" :class="v$.details.logo.$error? 'form-control is-invalid' : 'form-control' " placeholder="Upload Logo" @change="UploadLogo($event)">
                <span class="error invalid-feedback" v-if="v$.details.logo.$error">{{ v$.details.logo.$errors[0].$message }} </span>
            </div>
            <div class="form-group" v-if="!ModalReadOnly">
                <label class="required">Background</label>
                <textarea  :disabled="ModalReadOnly" :class="v$.details.description.$error? 'form-control is-invalid' : 'form-control' " placeholder="Company Background" v-model="details.description"></textarea>
                <span class="error invalid-feedback" v-if="v$.details.description.$error">{{ v$.details.description.$errors[0].$message }} </span>
            </div>
        </div>
        <div class="card-footer modal-footer">
            <button type="button" v-if="ModalReadOnly" :disabled="isLoading" class="btn btn-primary"  @click.prevent="Modify" > Modify </button>
            <button type="button" v-if="!ModalReadOnly" :disabled="isLoading" class="btn btn-success"  @click.prevent="Save" > Save </button>
            <button type="button" v-if="!ModalReadOnly" :disabled="isLoading" class="btn btn-secondary" @click.prevent="Cancel" > Cancel </button>
        </div>
    </div>


</template>

<script>
    import useValidate from '@vuelidate/core'
    import { required, helpers } from '@vuelidate/validators'
    import APIService from "../../../services/api.service"
    import { toast } from 'vue3-toastify';

    export default {
        data() {
            return {
                v$: useValidate(),
                ModalReadOnly: true,
                isLoading: true,
                details: {
                    logo: null,
                    description: ""
                },
                bodyFormData: new FormData()
            }
        },
        methods: {
            Modify() {
                this.ModalReadOnly = false;
            },
            Cancel() {
                this.ModalReadOnly = true;
            },
            async UploadLogo(event) {
                this.details.logo = event.target.files[0];
            },
            async Save() {
                this.isLoading = true;
                this.v$.$validate()
                if (!this.v$.$error) {
                    this.bodyFormData = new FormData();
                    this.bodyFormData.append("company_logo", this.details.logo);
                    this.bodyFormData.append("company_description", this.details.description);
                    await APIService.callAPI('details/update',  this.bodyFormData, function(response){
                        if(response.data.success){
                            toast.success(response.data.message);
                            this.isLoading = false;
                            this.ModalReadOnly = true;
                            this.getDetails();
                        } else {
                            toast.error(response.data.message);
                            this.isLoading = false;
                        }
                    }.bind(this));

                } else {
                    toast.error('There are items that require your attention.')
                    this.isLoading = false;
                }
            },
            async getDetails() {
                this.ModalReadOnly = true;
                this.isLoading = false;
                await APIService.callAPI('details/get', this.bodyFormData, function(response){
                    console.log(response);
                    if(response.data.success){
                        this.details.logo = response.data.data.Logo;
                        this.details.description = response.data.data.Description;
                    }
                }.bind(this));
            }
        },
        mounted() {
            this.getDetails();
        },
        validations() {
            return {
                details: {
                    description: { required },
                    logo: {
                        FileRules: helpers.withMessage("File is required", () => {
                            return this.details.logo? true: false;
                        }),
                        FileType: helpers.withMessage("File type should be Image", () => {
                            return this.details.logo.type == "image/png" || this.details.logo.type == "image/jpeg" || this.details.logo.type == "image/jpg"  ? true: false;
                        })
                    }
                },
            }
        }
    }
</script>