<template>

	<div class="card card-secondary card-tabs">
		<div class="card-header p-0 pt-1">
			<ul class="nav nav-tabs" id="custom-tabs-two-tab" role="tablist">
				<li class="nav-item" v-for="(item, i) in status" :key="i" :value="item.value">
					<a class="nav-link" @click.prevent="filterStatus(item.Value)" :class="i == 0? 'active': ''" data-toggle="pill" href="#" role="tab" aria-selected="false">
						{{ item.Label }}
						<span v-if="item.Count > 0" class="badge badge-danger">{{ item.Count }}</span>
					</a>
				</li>
			</ul>
		</div>
		<div class="card-body">
			<Loading v-if="loading"></Loading>
			<div v-if="data.data">
				<div v-if="data.paginate.total_result == 0">
					<span>No items on this list yet</span>
				</div>

				<div class="card report" v-for="(report, i) in data.data" :key="i">
					<div class="card-body" @click="ViewReport(report.id)" data-toggle="modal" data-target="#rightModal">
						<strong>{{ report.ReportNo }}</strong>
						<div class="row">
							<div class="col-1" style="min-width: 100px">
								<img :src="report.Thumbnail" class="rounded img-fluid">
							</div>
							<div class="col">
								<a class="float-right view-report" data-toggle="modal" data-target="#rightModal" @click.prevent="ViewReport(report.id)">
									<span class="fas fa-chevron-right"></span>
								</a>
								<div class="title">
									<div class="titleContent">
										{{ report.BriefDescription }}
									</div>
								</div>
								<span>{{ report.Area }}</span><br />
								<span>{{ report.SubmittedDate }}</span><br />
								<span v-if="report.Risk" :class="report.RiskClass"> {{ report.Risk }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer clearfix"  v-if="data.data">
            {{ data.paginate.display_result?? "" }} 
            <div class="card-tools">
                <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page > 1" @click.prevent="paginate(1)">First</button>
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page > 1" @click.prevent="paginate(data.paginate.current_page - 1)">Prev</button>
                    </div>
                    <select class="custom-select" @change="paginate($event.target.value)">
                        <option :selected="page == data.paginate.current_page" v-for="(page, i) in data.paginate.pages" :key="i"   :value="page">
                            Page {{ page }}
                        </option>
                    </select>
                    <div class="input-group-append">
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page < data.paginate.total_page" @click.prevent="paginate(data.paginate.current_page + 1)">Next</button>
                        <button  type="submit" class="btn btn-default" v-if="data.paginate.current_page < data.paginate.total_page" @click.prevent="paginate(data.paginate.total_page)">Last</button>
                    </div>
                </div>
            </div>
        </div>
		
	</div>


    <div v-if="showModal" class="modal show" role="dialog"  style="display: block;">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
				<div v-if="loading" class="overlay">
					<i class="fas fa-2x fa-sync fa-spin"></i>
				</div>
                <div class="modal-header">
                    <h5 class="modal-title">
						<i :class="details.RiskIconClass"></i> 
						Report - {{ details.ReportNumber }}
					</h5>
                </div>
                <div class="modal-body">
					<div class="card" v-if="details">
						<div class="card-header ui-sortable-handle" style="cursor: move;">
							<h3 class="card-title">
								Details
							</h3>
						</div>
						<div class="card-body">
							<table class="table table-bordered text-nowrap">
								<tbody>
									<tr>
										<td><b>Module</b></td>
										<td>{{ details.Module }}</td>
									</tr>
									<tr>
										<td><b>Report Number</b></td>
										<td>{{ details.ReportNumber }}</td>
									</tr>
									<tr>
										<td><b>Date Submitted</b></td>
										<td>{{ getHumanDate(details.SubmittedDate) }}</td>
									</tr>
									<tr v-if="details.UpdateDate">
										<td><b>Date Updated</b></td>
										<td>{{ getHumanDate(details.UpdateDate) }}</td>
									</tr>
									<tr>
										<td><b>Status</b></td>
										<td>{{ details.Status }}</td>
									</tr>
									<tr>
										<td><b>Risk</b></td>
										<td>{{ details.Risk }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
                    <div class="card" v-if="reporter">
						<div class="card-header ui-sortable-handle" style="cursor: move;">
							<h3 class="card-title">
								Reporter
							</h3>
						</div>
						<div class="card-body">
							<table class="table table-bordered text-nowrap">
								<tbody>
									<tr>
										<td width="200"><b>Name</b></td>
										<td>{{ reporter.Name }}</td>
									</tr>
									<tr>
										<td><b>Employee No.</b></td>
										<td>{{ reporter.EmployeeNumber }}</td>
									</tr>
									<tr>
										<td><b>Company</b></td>
										<td>{{ reporter.Company }}</td>
									</tr>
									<tr v-if="reporter.Branch">
										<td><b>Branch</b></td>
										<td>{{ reporter.Branch }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="card" v-if="blueprint">
						<div class="card-header ui-sortable-handle" style="cursor: move;">
							<h3 class="card-title">
								Layout
							</h3>

							<div class="card-tools">
								<button v-if="status_id == 1 && !blueprint.DetailedLocation" type="button" class="btn btn-xs btn-primary" @click.prevent="ModifyArea()">
									Update Layout
								</button>
								<button v-if="status_id == 1 && blueprint.DetailedLocation" type="button" class="btn btn-xs btn-primary" @click.prevent="ModifyDetailedLocation()">
									Update Layout
								</button>
							</div>

						</div>
						<div class="card-body">
							<table class="table table-bordered text-nowrap">
								<tbody>
									<tr v-if="blueprint.Branch">
										<td width="200"><b>Branch</b></td>
										<td>
											<div v-for="(item, i) in blueprint.Branch" :key="i">
												<span v-if="item.selected">{{ item.Label }}</span>
											</div>
										</td>
									</tr>
									<tr>
										<td><b>Location</b></td>
										<td v-if="ReadOnly">
											<div v-for="(item, i) in blueprint.Location" :key="i">
												<span v-if="item.selected">{{ item.Label }}</span>
											</div>
										</td>
									</tr>
									<tr v-if="blueprint.Area">
										<td><b>Area</b></td>
										<td v-if="ReadOnly">
											<div v-for="(item, i) in blueprint.Area" :key="i">
												<span v-if="item.selected">{{ item.Label }}</span>
											</div>
										</td>
									</tr>
									<tr v-if="blueprint.DetailedLocation">
										<td><b>Detailed Location</b></td>
										<td v-if="ReadOnly">
											<div v-for="(item, i) in blueprint.DetailedLocation" :key="i">
												<span v-if="item.selected">{{ item.Label }}</span>
											</div>
										</td>
									</tr>
									<tr>
										<td><b>Map</b></td>
										<td>
											<img @click="Mappreview(blueprint.Map)" :src="blueprint.Map" alt="" class="img-preview" width="50" height="50">
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
                    <div class="card" v-if="form">
						<div class="card-header ui-sortable-handle" style="cursor: move;">
							<h3 class="card-title">
								Report
							</h3>
						</div>
						<div class="card-body">
							<table class="table table-bordered text-nowrap">
								<tbody>
									<tr v-for="(item, i) in form" :key="i">
										<td><b> {{ item.Field.Label }}</b></td>
										<td v-if="ReadOnly">
											<div v-show="item.Field.Type == 'SELECT'" v-for="(data, i) in item.Field.Value" :key="i">
												<span v-if="data.selected">{{ data.Label }}</span>
											</div>
											<span v-if="item.Field.Type == 'DATE'"> {{ getHumanDateOnly(item.Field.Value) }}</span>
											<span v-if="item.Field.Type == 'TIME'"> {{ getHumanTimeOnly(item.Field.Value) }}</span>
											<span v-if="item.Field.Type == 'DATETIME'"> {{ getHumanDate(item.Field.Value) }}</span>
											<span v-if="item.Field.Type == 'NUMBER'"> {{ formatNumber(item.Field.Value) }}</span>
											<span v-if="item.Field.Type == 'TEXT' || item.Field.Type == 'MULTI_TEXT'" > {{ item.Field.Value }}</span>
											<span class="gallery"  v-if="item.Field.Type == 'MEDIA_PHOTO_VIDEO'">
												<div v-for="(data, i) in ViewMedia(item.Field.Value)" :key="i">
													<img v-if="get_url_extension(data) == 'png' || get_url_extension(data) == 'jpeg' || get_url_extension(data) == 'jpg'" @click="Mappreview(data)" :src="data" alt="" class="img-preview" width="100">
													<a v-if="get_url_extension(data) == 'avi' || get_url_extension(data) == 'mp4' || get_url_extension(data) == 'mpeg'" :href="data" target="_blank" rel="noopener noreferrer">Download Video</a>
												</div>
											</span>
										</td>
										<td width="10">
											<div class="btn-group btn-group-sm" v-if="item.Field.Type != 'MEDIA_PHOTO_VIDEO'">
												<button type="submit" @click.prevent="EditForm(item)"  class="btn btn-primary">
													<i class="fas fa-pen"></i>
												</button>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
                    <div class="card" v-if="respondents">
						<div class="card-header ui-sortable-handle" style="cursor: move;">
							<h3 class="card-title">
								Respondents
							</h3>
							<div class="card-tools">
								<button v-if="status_id == 1" type="button" class="btn btn-xs btn-primary" @click.prevent="ViewRespondent">
									Add Respondent
								</button>
							</div>
						</div>
						<div class="card-body">
							<table class="table table-bordered text-nowrap">
								<thead>
									<tr>
										<th>Employee No.</th>
										<th>Name</th>
										<th>Remarks</th>
										<th v-if="status_id == 1">Action</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, i) in respondents" :key="i">
										<td>{{ item.EmployeeNumber }}</td>
										<td>{{ item.Name }}</td>
										<td>{{ item.Remarks }}</td>
										<td width="10" v-if="status_id == 1">
											<div class="btn-group btn-group-sm">
												<button type="submit" @click.prevent="RemoveRespondent(item)"  class="btn btn-danger">
													<i class="fas fa-trash"></i>
												</button>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="card" v-if="watchers">
						<div class="card-header ui-sortable-handle" style="cursor: move;">
							<h3 class="card-title">
								Watchers
							</h3>
							<div class="card-tools">
								<button v-if="status_id > 0 && status_id < 5" type="button" class="btn btn-xs btn-primary" @click.prevent="ViewWatcher">
									Add Watcher
								</button>
							</div>
						</div>
						<div class="card-body">
							<table class="table table-bordered text-nowrap">
								<thead>
									<tr>
										<th>Employee No.</th>
										<th>Name</th>
										<th>Email</th>
										<th v-if="status_id > 0 && status_id < 5">Action</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, i) in watchers" :key="i">
										<td>{{ item.EmployeeNumber }}</td>
										<td>{{ item.Name }}</td>
										<td>{{ item.Email }}</td>
										<td width="10" v-if="status_id > 0 && status_id < 5">
											<div class="btn-group btn-group-sm">
												<button type="submit" @click.prevent="RemoveWatcher(item)"  class="btn btn-danger">
													<i class="fas fa-trash"></i>
												</button>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div class="card">
						<div class="card-header ui-sortable-handle" style="cursor: move;">
							<h3 class="card-title">
								Timeline
							</h3>
						</div>
						<div class="card-body">
							<div class="timeline">
								<div v-for="(item, i) in history" :key="i">
									<i :class="item.Class"></i>
									<div class="timeline-item">
										<span class="time"><i class="fas fa-clock"></i> {{ getHumanDate(item.Date )}}</span>
										<h3 class="timeline-header">{{ item.Name }}</h3>
										<div class="timeline-body" v-html="item.Content">
										</div>
									</div>
								</div>
								<div>
									<i class="fas fa-clock bg-gray"></i>
								</div>
							</div>
						</div>
					</div>

					<div class="card">
						<div class="card-header ui-sortable-handle" style="cursor: move;">
							<h3 class="card-title">
								Preventive Actions
							</h3>
						</div>
						<div class="card-body">
						</div>
					</div>

					<div class="card direct-chat direct-chat-primary">
						<div class="card-header ui-sortable-handle" style="cursor: move;">
							<h3 class="card-title">Comments</h3>
						</div>
						
						<div class="card-body">
						
							<div class="direct-chat-messages">
							
								<div  v-for="(data, i) in comments" :key="i"  class="direct-chat-msg">
									<div class="direct-chat-infos clearfix">
										<span class="direct-chat-name float-left"> {{ data.Name }}</span>
										<span class="direct-chat-timestamp float-right"> {{ getHumanDate(data.Date) }} </span>
									</div>
															
									<div class="direct-chat">
										{{ data.Comment }}
									</div>
								
								</div>
							
							</div>
						
						</div>
						
						<div class="card-footer">
							<div class="input-group">
								<input type="text" name="message" placeholder="Type Comment ..." class="form-control" v-model="commentInput" v-on:keyup.enter="sendComment" >
								<span class="input-group-append">
									<button type="button" class="btn btn-primary"  @click.prevent="sendComment">Send</button>
								</span>
							</div>
						</div>
						
					</div>
					
				</div>
                <div class="modal-footer" v-if="status_id == 1">
                    <button type="button" :disabled="loading" v-if="ReadOnly && respondents.length > 0" class="btn btn-success" @click.prevent="ApproveReport()" >Approve</button>
                    <button type="button" :disabled="loading" v-if="ReadOnly" class="btn btn-danger" @click.prevent="RejectReport()" >Reject</button>
                    <button type="button" :disabled="loading" v-if="ReadOnly" class="btn btn-secondary" @click.prevent="hideModal()" >Close</button>
                    <button type="button" :disabled="loading"  v-if="!ReadOnly" class="btn btn-success" @click.prevent="UpdateReport()" >Update Report</button>
                    <button type="button" :disabled="loading"  v-if="!ReadOnly" class="btn btn-warning" @click.prevent="CancelModify()" >Cancel</button>
                </div>
                <div class="modal-footer" v-if="status_id == 2 || status_id == 5 || status_id == 6">
                    <button type="button" :disabled="loading" v-if="ReadOnly" class="btn btn-secondary" @click.prevent="hideModal()" >Close</button>
                </div>
            </div>
        </div>
    </div>


	<div v-if="showWatcherModal" class="modal show" role="dialog"  style="display: block;">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
				<div v-if="loading" class="overlay">
					<i class="fas fa-2x fa-sync fa-spin"></i>
				</div>
                <div class="modal-header">
                    <h5 class="modal-title">Add Watcher</h5>
                </div>
                <div class="modal-body">
					<Watchers></Watchers>
				</div>
                <div class="modal-footer">
                    <button type="button" :disabled="loading" class="btn btn-warning" @click.prevent="CloseWatcher()" >Close</button>
                </div>
            </div>
        </div>
    </div>

	<div v-if="showRespondentModal" class="modal show" role="dialog"  style="display: block;">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
				<div v-if="loading" class="overlay">
					<i class="fas fa-2x fa-sync fa-spin"></i>
				</div>
                <div class="modal-header">
                    <h5 class="modal-title">Add Respondent</h5>
                </div>
                <div class="modal-body">
					<Respondents></Respondents>
				</div>
                <div class="modal-footer">
                    <button type="button" :disabled="loading" class="btn btn-warning" @click.prevent="CloseRespondent()" >Close</button>
                </div>
            </div>
        </div>
    </div>


	<div v-if="showAreaModal" class="modal show" role="dialog"  style="display: block;">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
				<div v-if="loading" class="overlay">
					<i class="fas fa-2x fa-sync fa-spin"></i>
				</div>
                <div class="modal-header">
                    <h5 class="modal-title">Update Location</h5>
                </div>
                <div class="modal-body">
					<Areas></Areas>
				</div>
                <div class="modal-footer">
                    <button type="button" :disabled="loading" class="btn btn-warning" @click.prevent="CloseArea()" >Close</button>
                </div>
            </div>
        </div>
    </div>

	<div v-if="showDetailedLocationModal" class="modal show" role="dialog"  style="display: block;">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
				<div v-if="loading" class="overlay">
					<i class="fas fa-2x fa-sync fa-spin"></i>
				</div>
                <div class="modal-header">
                    <h5 class="modal-title">Update Location</h5>
                </div>
                <div class="modal-body">
					<DetailedLocation></DetailedLocation>
				</div>
                <div class="modal-footer">
                    <button type="button" :disabled="loading" class="btn btn-warning" @click.prevent="CloseDetailedLocation()" >Close</button>
                </div>
            </div>
        </div>
    </div>



	<!-- Modal -->
	<div ref="ReportViewer" class="modal fade" id="rightModal" tabindex="-1" role="dialog" aria-labelledby="rightModalLabel" aria-hidden="true" data-bs-focus="false">
		<div class="modal-dialog modal-dialog-slideout" role="document">
			<div class="modal-content">
				<View :id="report_id" ref="reportViewer"></View>
			</div>
		</div>
	</div>
</template>
<script>

import $ from 'jquery'
import APIService from "../../../services/api.service"
import Loading from "../../../template/Loading.vue"
import Watchers from "./Watchers.vue"
import Respondents from "./Respondents.vue"
import View from "./View.vue"
import Areas from "./Areas.vue"
import DetailedLocation from "./DetailedLocation.vue"
import moment from 'moment'
import {preview} from 'vue3-image-preview';
import { toast } from 'vue3-toastify';
import Swal from 'sweetalert2'

export default {
	components: {
		Loading,
		Watchers,
		Areas,
		DetailedLocation,
		View,
		Respondents
	},
	data() {
		return {
			data: [],
			keyword: '',
			daterange: [],
			page: 1,
            module : this.$route.query.module,
            report_no : this.$route.query.report_no,
			risk: [
				{
					label : "All Risk",
					value : null
				},
				{
					label : "High Risk",
					value : 1
				},
				{
					label : "Medium Risk",
					value : 2
				},
				{
					label : "Low Risk",
					value : 3
				}
			],
			status: [],
			filter_status: 1,
			filter_risk: null,
			bodyFormData : new FormData(),
			ReadOnly: true,
			loading: false,
			showModal: false,
			report_id: null,
			details: [],
			reporter: [],
			blueprint: [],
			form: [],
			history: [],
			watchers: [],
			respondents: [],
			commentInput: "",
			comments: [],
			dashboard: [],
			showWatcherModal: false,
			status_id: null,
			showRespondentModal: false,
			showAreaModal: false,
			showDetailedLocationModal: false,
			selected_data: null
		}
	},
	watch:{
		$route: {
			handler: function(newRouteValue){
				this.clear();
				this.data = [];
				this.module = newRouteValue.query.module
				if(!this.module){
					window.location.replace("/");
				}


				this.report_no = newRouteValue.query.report_no
				if(this.report_no){
					this.keyword = this.report_no;
				}

				this.search();
				this.getDashboard();
			},
			deep: true
		}
	},
	methods: {
		ViewReport(report) {
			console.log(report);
			this.report_id = report;
		},
		get_url_extension(url) {
			return url.split('.').pop().trim().toLowerCase();
		},
		formatNumber(number) {
			return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 4 }).format(
				number,
			);
		},	
		getHumanDateOnly(date) {
			return moment(date).format('L');
		},
		getHumanTimeOnly(date) {
			return moment(date).format('LL');
		},
		getHumanDate(date) {
			return moment(date).format('LLLL');
		},
		clear() {
			this.filter_status = null;
			this.filter_risk = null;
			this.keyword = "";
			this.search(this.keyword);
		},
		filterStatus(i) {
			this.filter_status = i;
			this.page = 1;
			this.search(this.keyword);
		},
		paginate(page){
			this.page = page;
			this.search();
		},
		hideModal() {
			this.showModal = false;
		},
		async sendComment(){
			if(this.commentInput.trim() != ""){
				this.loading = true;
				this.bodyFormData = new FormData();
				this.bodyFormData.append("report_id", this.report_id);
				this.bodyFormData.append("comment", this.commentInput);
				await APIService.callAPI('report/add-comment', this.bodyFormData, function() {
					this.loading = false;
					this.commentInput = "";
					this.getComments();
				}.bind(this));
			} else {
				toast.error("Comment is empty");
			}
		},
		async getComments(){
			this.loading = true;
			this.bodyFormData = new FormData();
			this.bodyFormData.append("report_id", this.report_id);
			await APIService.callAPI('report/get-comments', this.bodyFormData, function(result) {
				this.loading = false;
				this.comments = result.data.data;
			}.bind(this));
		},
		async getDashboard(){
			this.loading = true;
			this.bodyFormData = new FormData();
            this.bodyFormData.append("code", this.module);
			await APIService.callAPI('report/dashboard', this.bodyFormData, function(result) {
				this.loading = false;
				this.status = result.data.data;
			}.bind(this));
		},
		getStatus(status){
			switch (status) {
				case 1:
					return "To Assign";
				case 2:
					return "To Respond";
				case 3:
					return "For Review";
				case 4:
					return "For Verification";
				case 5:
					return "Completed";
				case 6:
					return "Rejected";
				default:
					return "No Status";
			}
		},
		async search() {

			this.data = [];
			this.loading = true;
			this.bodyFormData = new FormData();
			this.bodyFormData.append("search", this.keyword);
			this.bodyFormData.append("page", this.page);
            this.bodyFormData.append("code", this.module);
			if(this.filter_status){
				this.bodyFormData.append("status", this.filter_status);
			}
			if(this.filter_risk){
				this.bodyFormData.append("risk", this.filter_risk);
			}
			await APIService.callAPI('report/list', this.bodyFormData, function(result) {
				this.data = result.data;
				this.loading = false;
			}.bind(this));
		},
		async View(report_id) {
			this.details = [];
			this.reporter = [];
			this.blueprint = [];
			this.history = [];
			this.watchers = [];
			this.respondents = [];
			this.form = [];
			this.status_id = null;
			
			this.report_id = report_id;
			this.showModal = true;
			this.loading = true;
			this.bodyFormData = new FormData();
			this.bodyFormData.append("report_id", report_id);
			await APIService.callAPI('report/get', this.bodyFormData, function(result) {
				if(result.data.success){
					this.status_id = result.data.details.StatusID;
					this.details = result.data.details;
					this.reporter = result.data.reporter;
					this.blueprint = result.data.blueprint;
					this.history = result.data.history;
					this.watchers = result.data.watcher;
					this.respondents = result.data.respondent;
					this.form = result.data.forms;
					this.getComments();
				} else {
					this.showModal = false;
				}
				this.loading = false;
			}.bind(this));
		},
		ViewMedia(JSONitem) {
			JSONitem = JSON.parse(JSONitem);
			return JSONitem;
		},
		Mappreview: function(image) {
			preview({
				images: image
			});
		},
		ModifyReport(){
			this.ReadOnly = false;
		},
		async RejectReport(){
			const { value: text } = await Swal.fire({
				title: 'Reject Report',
				showCancelButton: true,
				confirmButtonText: 'Yes, reject report',
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				input: "textarea",
				inputLabel: "Why are you rejecting this report?",
				inputPlaceholder: "Explain clearly why you are rejecting this report",
				inputAttributes: {
					"aria-label": "Explain clearly why you are rejecting this report"
				}
			});
			if (text) {
				this.loading = true;
				this.bodyFormData = new FormData();
				this.bodyFormData.append("report_id", this.report_id);
				this.bodyFormData.append("comment", text);
				await APIService.callAPI('report/reject', this.bodyFormData, function(result) {
					if(result.data.success){
						toast.success(result.data.message);
						this.View(this.report_id);
						this.getComments();
					} else {
						toast.error(result.data.message);
						this.showModal = false;
					}
					this.ReadOnly = true;
					this.loading = false;
				}.bind(this));
			}
		},
		ViewWatcher(){
			this.showWatcherModal = true;
		},
		CloseWatcher(){
			this.showWatcherModal = false;
		},
		ViewRespondent(){
			this.showRespondentModal = true;
		},
		CloseRespondent(){
			this.showRespondentModal = false;
		},
		async AddWatcher(employee_id){
			this.loading = true;
			this.bodyFormData = new FormData();
			this.bodyFormData.append("report_id", this.report_id);
			this.bodyFormData.append("watcher", employee_id);
			await APIService.callAPI('report/add-watcher', this.bodyFormData, function(result) {
				if(result.data.success){
					toast.success(result.data.message);
					this.$refs.reportViewer.getReportDetails(this.report_id);
					this.CloseWatcher();
				} else {
					toast.error(result.data.message);
					this.showModal = false;
				}
				this.ReadOnly = true;
				this.loading = false;
			}.bind(this));
			
		},
		async AddRespondent(employee_id, remarks){
			this.loading = true;
			this.bodyFormData = new FormData();
			this.bodyFormData.append("report_id", this.report_id);
			this.bodyFormData.append("respondent", employee_id);
			this.bodyFormData.append("remarks", remarks);
			await APIService.callAPI('report/add-respondent', this.bodyFormData, function(result) {
				if(result.data.success){
					toast.success(result.data.message);
					this.$refs.reportViewer.getReportDetails(this.report_id);
					this.CloseRespondent();
				} else {
					toast.error(result.data.message);
					this.showModal = false;
				}
				this.ReadOnly = true;
				this.loading = false;
			}.bind(this));
			
		},
		async RemoveRespondent(employee){

			Swal.fire({
				title: 'Remove Respondent',
				html: `Are you sure you want to remove <b>${employee.Name}</b> as respondent?`,
				showCancelButton: true,
				confirmButtonText: 'Yes, remove respondent',
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.loading = true;
					this.bodyFormData = new FormData();
					this.bodyFormData.append("report_id", this.report_id);
					this.bodyFormData.append("respondent", employee.ID);
					await APIService.callAPI('report/remove-respondent', this.bodyFormData, function(result) {
						if(result.data.success){
							toast.success(result.data.message);
							this.View(this.report_id);
							this.getComments();
							this.CloseRespondent();
						} else {
							toast.error(result.data.message);
							this.showModal = false;
						}
						this.ReadOnly = true;
						this.loading = false;
					}.bind(this));
				}
			});
			
		},
		async RemoveWatcher(employee){

			Swal.fire({
				title: 'Remove Watcher',
				html: `Are you sure you want to remove <b>${employee.Name}</b> as watcher?`,
				showCancelButton: true,
				confirmButtonText: 'Yes, remove watcher',
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.loading = true;
					this.bodyFormData = new FormData();
					this.bodyFormData.append("report_id", this.report_id);
					this.bodyFormData.append("watcher", employee.ID);
					await APIService.callAPI('report/remove-watcher', this.bodyFormData, function(result) {
						if(result.data.success){
							toast.success(result.data.message);
							this.View(this.report_id);
							this.getComments();
							this.CloseRespondent();
						} else {
							toast.error(result.data.message);
							this.showModal = false;
						}
						this.ReadOnly = true;
						this.loading = false;
					}.bind(this));
				}
			});
			
		},
		async ApproveReport(){

			const { value: risk } = await Swal.fire({
				title: "Approve Report",
				html: `Are you sure you want to approve this report?`,
				input: "select",
				inputOptions: {
					1: "High Risk (24hrs to implement control)",
					2: "Medium Risk (7days to implement control)",
					3: "Low Risk (as per agreed timeline)"
				},
				inputPlaceholder: "Select Risk Category",
				confirmButtonText: 'Approve',
				confirmButtonColor: '#28a745',
				cancelButtonColor: '#3085d6',
				showCancelButton: true,
				inputValidator: (value) => {
					if (!value) {
						return "You need to select risk category.";
					}
				}
			});
			if (risk) {
				this.loading = true;
				this.bodyFormData = new FormData();
				this.bodyFormData.append("report_id", this.report_id);
				this.bodyFormData.append("risk", risk);
				await APIService.callAPI('report/approve', this.bodyFormData, function(result) {
					if(result.data.success){
						toast.success(result.data.message);
						this.View(this.report_id);
						this.getComments();
					} else {
						toast.error(result.data.message);
						this.showModal = false;
					}
					this.ReadOnly = true;
					this.loading = false;
				}.bind(this));
			}
		},
		async EditForm(item){
			switch (item.Field.Type) {
				case "SELECT":
					var select_items = [];
					for (const listelement of item.Field.Value) {
						select_items[listelement.Id] = listelement.Label;
					}
					this.SWAL_SELECT(item.Field.Id, item.Field.Label, select_items);
					break;
				case "TEXT":
					this.SWAL_GENERIC(item.Field.Id, item.Field.Label, "text", item.Field.Value);
					break;
				case "MULTI_TEXT":
					this.SWAL_GENERIC(item.Field.Id, item.Field.Label, "textarea", item.Field.Value);
					break;
				case "NUMBER":
					this.SWAL_GENERIC(item.Field.Id, item.Field.Label, "number", item.Field.Value);
					break;
				default:
					this.SWAL_GENERIC(item.Field.Id, item.Field.Label, "text", item.Field.Value);
					break;
			}
			
		},
		async SWAL_SELECT(fieldId, label, options){
			const { value: value } =  await Swal.fire({
				title: `Update ${label}`,
				input: "select",
				inputOptions: options,
				inputPlaceholder: `Select ${label}`,
				confirmButtonText: 'Update',
				confirmButtonColor: '#28a745',
				cancelButtonColor: '#3085d6',
				showCancelButton: true,
				inputValidator: (value) => {
					if (!value) {
						return `You need to select ${label}.`;
					}
				}
			});
			if (value) {
				this.UpdateForm(fieldId, value);
			}
		},
		async SWAL_GENERIC(fieldId, label, type, inputValue){
			const { value: value } =  await Swal.fire({
				title: `${label}`,
				input: type,
				inputValue,  
				confirmButtonText: 'Update',
				confirmButtonColor: '#28a745',
				cancelButtonColor: '#3085d6',
				showCancelButton: true,
				inputValidator: (value) => {
					if (!value) {
						return `${label} is required.`;
					}
				}
			});
			if (value) {
				this.UpdateForm(fieldId, value);
			}
		},
		async UpdateForm(FieldID, Value){
			this.loading = true;
			this.bodyFormData = new FormData();
			this.bodyFormData.append("report_id", this.report_id);
			this.bodyFormData.append("field_id", FieldID);
			this.bodyFormData.append("value", Value);
			await APIService.callAPI('report/update-form', this.bodyFormData, function(result) {
				if(result.data.success){
					toast.success(result.data.message);
					this.View(this.report_id);
					this.getComments();
				} else {
					toast.error(result.data.message);
					this.showModal = false;
				}
				this.ReadOnly = true;
				this.loading = false;
			}.bind(this));
		},
		ModifyArea(){
			this.showAreaModal = true;
		},
		CloseArea(){
			this.showAreaModal = false;
		},
		ModifyDetailedLocation(){
			this.showDetailedLocationModal = true;
		},
		CloseDetailedLocation(){
			this.showDetailedLocationModal = false;
		},
		async updateArea(area){
			this.loading = true;
			this.bodyFormData = new FormData();
			this.bodyFormData.append("report_id", this.report_id);
			this.bodyFormData.append("area", area);
			await APIService.callAPI('report/update-area', this.bodyFormData, function(result) {
				if(result.data.success){
					toast.success(result.data.message);
					this.View(this.report_id);
					this.getComments();
					this.CloseArea();
				} else {
					toast.error(result.data.message);
					this.showModal = false;
				}
				this.ReadOnly = true;
				this.loading = false;
			}.bind(this));
			
		},
		async updateDetailedLocation(location){
			this.loading = true;
			this.bodyFormData = new FormData();
			this.bodyFormData.append("report_id", this.report_id);
			this.bodyFormData.append("detailed_location", location);
			await APIService.callAPI('report/update-detailed-location', this.bodyFormData, function(result) {
				if(result.data.success){
					toast.success(result.data.message);
					this.View(this.report_id);
					this.getComments();
					this.CloseArea();
				} else {
					toast.error(result.data.message);
					this.showModal = false;
				}
				this.ReadOnly = true;
				this.loading = false;
			}.bind(this));
			
		},
	},
	mounted() {
		if(this.report_no){
			this.filter_status = null;
			this.filter_risk = null;
			this.keyword = this.report_no;
		}
		this.search();
		this.getDashboard();
		$.fn.modal.Constructor.prototype._enforceFocus = function() {};

	},
};
</script>