import axios from 'axios';
import authHeader from './auth-header';
import { toast } from 'vue3-toastify';


const API_URL = process.env.VUE_APP_URL_API
const API_TYPE = process.env.VUE_APP_API_TYPE

class APIService {
    async callAPI(uri, bodyFormData = {}, callback) {

        let posturl = `${API_URL}/${API_TYPE}/${uri}`;
        if(uri == "auth/details"){
            posturl = `${API_URL}/${uri}`;
        }

        return await axios
        .post(
            posturl,
            bodyFormData, 
            { 
                headers: authHeader() 
            }
        ).then(function (response) {
            return callback(response);
        }).catch(async function (err) {
            let config = err.config;
            let request_url = err.config.url;
            let response_code = err.response.status;
            const currentUrl = window.location.pathname;

            const AccountSuspended = err.response.data.message ==  "Your account has been disabled" ? true : false;
            console.log(err.response.data.message);
            if(request_url !== `${API_URL}/${API_TYPE}/authenticate`){
                if (response_code === 401){ 
                    if(AccountSuspended || (!localStorage.getItem('refresh_token') && currentUrl != "/login")){
                        localStorage.removeItem('token');
                        localStorage.removeItem('refresh_token');
                        localStorage.removeItem('displayName');
                        window.location.replace("/login");
                    }

                    //refresh token
                    const bodyFormData = new FormData();
                    bodyFormData.append("refresh_token", localStorage.getItem("refresh_token"));
                    await axios.post(
                        API_URL + "/token/refresh", 
                        bodyFormData
                    ).then( async function (rs) {
                        localStorage.setItem('token', rs.data.token);
                        localStorage.setItem('refresh_token', rs.data.refresh_token);
                        config.headers["Authorization"] = `Bearer ${rs.data.token}`;

                        //retry call job
                        await axios(config).then(function (response) {
                            callback(response);
                        }).catch(async function (err) {
                            callback({
                                status : false,
                                message : err.response.data.message
                            });
                        });
                    }).catch(async function () {
                        localStorage.removeItem('token');
                        localStorage.removeItem('refresh_token');
                        localStorage.removeItem('displayName');
                        window.location.replace("/login");
                    });
                }

                if (response_code === 500){ 
                    let error_message = err.response.data.detail;
                    if(error_message.includes("foreign key constraint fails")){
                        toast.error("The data is being used and cannot be deleted.");
                    } else {
                        toast.error("Unexpected error. Please try again or contact support.");
                    }
                }

                if (response_code === 404){ 
                    toast.error("Unexpected error. Please try again or contact support.");
                }
            } else {
                callback({
                    status : false,
                    message : err.response.data.message
                });
            }
        });
    }

}

export default new APIService();