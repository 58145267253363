<template>
    <body class="hold-transition login-page">
		<Preloader v-if="isLoading"></Preloader>
        <Components></Components>
	</body>
</template>

<script>

import Components from "./Components.vue"
import AuthService from "../../services/auth.service"
import Preloader from "../../template/Preloader.vue"
export default {
	name: "App",
	data() {
		return {
			isLoading: false
		}
	},
	components: {
		Preloader,
        Components
	},
	methods: {
		setLoading(loading) {
			this.isLoading = loading;
		}
	},
	mounted() {
        if(AuthService.check().status.loggedIn){
            this.$router.push({ path: '/' })
        }
	}
};
</script>
