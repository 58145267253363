<template>
    <div class="d-flex justify-content-center">
        <span class="spinner-border"></span>
    </div>
</template>

<script>
export default {
    
}
</script>
