<template>
	<body class="hold-transition sidebar-mini layout-fixed">
		<div class="wrapper">
			<Navbar></Navbar>
			<Sidebar></Sidebar>
			<div class="content-wrapper">
				<ContentHeader pagetitle="Notifications" :navigation="navigation"></ContentHeader>
				<section class="content">
					<div class="container-fluid" >
						<Loading v-if="!isLoaded"></Loading>
                        <Components v-if="isLoaded" :data="data" ref="component"></Components>
					</div>
				</section>
			</div>
			<Footer></Footer>
			<ControlSidebar></ControlSidebar>
		</div>
	</body>
</template>
<script>

import APIService from "../../services/api.service"
import ContentHeader from "../../template/ContentHeader.vue"
import Navbar from "../../template/Navbar.vue";
import Sidebar from "../../template/Sidebar.vue";
import Footer from "../../template/Footer.vue";
import ControlSidebar from "../../template/ControlSidebar.vue"
import Loading from "../../template/Loading.vue"
import Components from "./Components.vue"

export default {
	components: {
		ContentHeader,
        Components,
		Navbar,
		Sidebar,
		Footer,
		Loading,
		ControlSidebar
	},
    data() {
        return {
            isLoaded: false,
			navigation : {
				"Home" : "/",
				"Notifications" : "#",
			},
			data: {},
            bodyFormData : new FormData()
        }
    },
	methods: {
        async getList() {
            this.search();
        },
		async search(keyword = "", page = 1) {
            this.isLoaded = false;
			this.bodyFormData.append("search", keyword);
			this.bodyFormData.append("page", page);
            await APIService.callAPI('notifications/list', this.bodyFormData, function(result) {
				this.data = result.data;
				this.isLoaded = true;
			}.bind(this));
		},
	},
	mounted() {
        this.getList();
	}
};
</script>