<template>
    <div class="card">
        <div class="card-header">
            <div class="card-tools">
                <div class="input-group input-group-sm">
                    <input type="text" @keydown.enter="search"  v-model="keyword" class="form-control float-right" placeholder="Search">
                    <div class="input-group-append">
                        <button type="submit" @click.prevent="search" class="btn btn-default">
                            <i class="fas fa-search"></i>
                        </button>
                        <button v-if="keyword" type="submit" @click.prevent="clear" class="btn btn-danger" >
                            <i class="fas fa-sync-alt"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body table-responsive p-0">
            <Loading v-if="loading"></Loading>
            <table class="table table-bordered text-nowrap">
                <thead>
                    <tr>
                        <th>Employee No.</th>
						<th>Name</th>
						<th>Email</th>
						<th>Branch</th>
						<th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(employee, i) in data.data" :key="i">
                        
                        <td>{{ employee.employee_number }}</td>
                        <td>{{ employee.first_name + " " + employee.last_name }}</td>
                        <td>{{ employee.email }}</td>
                        <td>{{ employee.branch }}</td>
                        <td>
                            <div class="btn-group btn-group-sm">
                                <button type="submit" @click.prevent="Add(employee.id)" class="btn btn-info">
                                    <i class="fas fa-plus"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div class="card-footer clearfix"  v-if="data.data">
            {{ data.paginate.display_result?? "" }} 
            <div class="card-tools">
                <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page > 1" @click.prevent="paginate(1)">First</button>
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page > 1" @click.prevent="paginate(data.paginate.current_page - 1)">Prev</button>
                    </div>
                    <select class="custom-select" @change="paginate($event.target.value)">
                        <option :selected="page == data.paginate.current_page" v-for="(page, i) in data.paginate.pages" :key="i"   :value="page">
                            Page {{ page }}
                        </option>
                    </select>
                    <div class="input-group-append">
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page < data.paginate.total_page" @click.prevent="paginate(data.paginate.current_page + 1)">Next</button>
                        <button  type="submit" class="btn btn-default" v-if="data.paginate.current_page < data.paginate.total_page" @click.prevent="paginate(data.paginate.total_page)">Last</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import APIService from "../../../services/api.service"
    import useValidate from '@vuelidate/core'
    import moment from 'moment'
    import Loading from "../../../template/Loading.vue"

    export default {
        components: {
            Loading
        },
        data() {
            return {
                v$: useValidate(),
                ModalLabel: '',
                su : false,
                keyword: '',
                data: [],
                page: 1,
                loading: false,
                dataLoaded: false,
                submitButtonLabel : 'Save Changes',
                ModalShow: false,
                ModalBatchShow: false,
                ModalReadOnly: false,
                bodyFormData : new FormData(),
                uploading: false,
                branch: [],
                employee: {
                    id : null,
                    first_name: "",
                    middle_name: "",
                    last_name: "",
                    employee_no: "",
                    gender: "",
                    email: "",
                    contact: "",
                    branch: "",
                },
                batch_upload : {
                    branch: null,
                    file: null,
                    template : 'https://cdn.notisafe.ph/templates/employee-batch-upload-template.xlsx'
                }
            }
        }, 
        methods: {
            paginate(page){
                this.page = page;
                this.search();
            },
            resetFields(){

            },
            getHumanDate(date) {
                return moment(date).format('LL');
            },
            showModal() {
                this.ModalShow = true;
            },
            showBatchModal() {
                this.getBranches();
                this.ModalBatchShow = true;
            },
            hideModal() {
                this.ModalShow = false;
                this.ModalBatchShow = false;
            },
            ModifyData() {
                this.ModalReadOnly = false;
            },
            clear() {
                this.keyword = "";
                this.search(this.keyword);
            },
            downloadTemplate() {
                window.location.href = this.batch_upload.template;
            },
            Add(employee_id) {
				this.$parent.AddWatcher(employee_id);
            },
            async getList() {
                this.resetFields();
                this.search();
            },
            async search() {
                this.loading = true;
                this.bodyFormData = new FormData();
                this.bodyFormData.append("search", this.keyword);
                this.bodyFormData.append("page", this.page);
                await APIService.callAPI('employee/list', this.bodyFormData, function(result) {
                    this.data = result.data;
                    this.loading = false;
                }.bind(this));
            }
        },
        mounted() {
            this.getList();
        },
        validations() {
        }
    }
</script>