<template>
	<!-- Preloader -->
	<div class="preloader flex-column justify-content-center align-items-center">
		<img
			class="animation__shake"
			src="../assets/img/AdminLTELogo.png"
			alt="AdminLTELogo"
			height="60"
			width="60"
		/>
	</div>
</template>

<script>
export default {};
</script>