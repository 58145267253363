<template>
    <div class="card">
        <div class="card-header">
            <div class="card-tools">
                <div class="input-group input-group-sm">
                    <input type="text" @keydown.enter="search"  v-model="keyword" class="form-control float-right" placeholder="Search">
                    <div class="input-group-append">
                        <button type="submit" @click.prevent="search" class="btn btn-default">
                            <i class="fas fa-search"></i>
                        </button>
                        <button v-if="keyword" type="submit" @click.prevent="clear" class="btn btn-danger" >
                            <i class="fas fa-sync-alt"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body table-responsive p-0">
            <Loading v-if="isLoading"></Loading>
            <table class="table table-bordered text-nowrap">
                <thead>
                    <tr>
                        <th>Location</th>
						<th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in data.data" :key="i">
                        <td><span v-if="item.Branch != undefined">{{ item.Branch }} /</span> {{ item.Location }} / {{ item.area_name }} / {{ item.DetailedLocation }}</td>
                        <td>
                            <div class="btn-group btn-group-sm">
                                <button type="submit" @click.prevent="Add(item, `${item.Branch}/${item.Location}/${item.area_name}/${item.DetailedLocation}`)" class="btn btn-info">
                                    <i class="fas fa-plus"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div class="card-footer clearfix"  v-if="data.data">
            {{ data.paginate.display_result?? "" }} 
            <div class="card-tools">
                <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page > 1" @click.prevent="paginate(1)">First</button>
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page > 1" @click.prevent="paginate(data.paginate.current_page - 1)">Prev</button>
                    </div>
                    <select class="custom-select" @change="paginate($event.target.value)">
                        <option :selected="page == data.paginate.current_page" v-for="(page, i) in data.paginate.pages" :key="i"   :value="page">
                            Page {{ page }}
                        </option>
                    </select>
                    <div class="input-group-append">
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page < data.paginate.total_page" @click.prevent="paginate(data.paginate.current_page + 1)">Next</button>
                        <button  type="submit" class="btn btn-default" v-if="data.paginate.current_page < data.paginate.total_page" @click.prevent="paginate(data.paginate.total_page)">Last</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import Loading from "../../../template/Loading.vue"
    import APIService from "../../../services/api.service"
    import Swal from 'sweetalert2'
    import {preview} from 'vue3-image-preview';

    export default {
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                data: [],
                locations: [],
                keyword: "",
                page: 1,
                ModalLabel: "New Area",
                submitButtonLabel: "Save Changes",
                ModalReadOnly: true,
                ModalShow: false,
                input: {
                    id: null,
                    location: "",
                    area : null,
                    map : null,
                    note: null
                }
            }
        },
        methods: {
            search() {
                this.data = [];
                this.getList();
            },
            paginate(page){
                this.page = page;
            },
            clear() {
                this.input.id = null;
                this.input.location = null;
                this.input.area = null;
                this.keyword = "";
                this.getList();
            },
            async getList(){
                this.isLoading = true;
                this.data = [];
                this.bodyFormData = new FormData();
                this.bodyFormData.append("search", this.keyword);
                this.bodyFormData.append("page", this.page);
                await APIService.callAPI('blueprint/get-detailed-location-list', this.bodyFormData, function(result) {
                    this.data = result.data;
                    this.isLoading = false;
                }.bind(this));
            }, 
            Mappreview: function(item) {
                preview({
                    images: item.LocationMap
                });
            },

            async Add(location, new_location) {
                Swal.fire({
                    title: `Update Location`,
                    html: `Are you sure you want to update the location of the report to ${new_location}?`,
                    showCancelButton: true,
                    confirmButtonText: 'Update',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.$parent.updateDetailedLocation(location.id);
                    }
                })
            },
        },
        mounted() {
            this.getList();
        }
    }
</script>