<template>
	<!-- Navbar -->
	<nav class="main-header navbar navbar-expand navbar-white navbar-light">
		<!-- Left navbar links -->
		<ul class="navbar-nav">
				<li class="nav-item">
					<a class="nav-link" data-widget="pushmenu" href="#" role="button">
					<i class="fas fa-bars"></i>
				</a>
			</li>
		</ul>

		<!-- Right navbar links -->
		<ul class="navbar-nav ml-auto">
			<li class="nav-item">
				<a class="nav-link" data-widget="fullscreen" href="#" role="button">
					<i class="fas fa-expand-arrows-alt"></i>
				</a>
			</li>
			<li class="nav-item dropdown ">
				<a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="true">
					<i class="far fa-bell"></i>
					<span v-if="notification.count > 0" class="badge badge-warning navbar-badge">{{ notification.count }}</span>
				</a>
				<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right notification-list" style="left: inherit; right: 0px;">
					<router-link to="/notifications" v-for="(item, i) in notification.items" :key="i" class="dropdown-item">
						<div class="media">
							<div class="media-body">
								<h3 class="dropdown-item-title" :class="!item.isRead ? 'font-weight-bold' : ''">
									{{ item.u_Title }}
								</h3>
								<p class="text-sm overflow-hidden" >{{ limitText(item.u_Body, 40) }}</p>
								<p class="text-sm text-muted"><i class="far fa-clock mr-1"></i>{{ getHumanDate(item.u_create_date) }}</p>
							</div>
						</div>
					</router-link>

					<div class="dropdown-divider"></div>
					<router-link to="/notifications" class="dropdown-item dropdown-footer">
						See All Notifications
					</router-link>
				</div>
				</li>
			<li class="nav-item dropdown">
				<a class="nav-link" data-toggle="dropdown" href="#" @click="checkUser()">
					<i class="far fa-user"></i>
				</a>
				<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
					<span class="dropdown-item dropdown-header">{{ loggedName }}</span>
					<div class="dropdown-divider"></div>
					<router-link to="/change-password" class="dropdown-item">
						<i class="fas fa-lock mr-2"></i>
						Change Password
					</router-link>
					<a href="#" @click.prevent="logOut"  class="dropdown-item">
						<i class="fas fa-sign-out-alt mr-2"></i> Logout
					</a>
				</div>
			</li>
		</ul>
	</nav>
  <!-- /.navbar -->
</template>

<script>
import AuthService from "../services/auth.service"
import moment from 'moment'
import APIService from "../services/api.service"
import { toast } from 'vue3-toastify';
export default {
	data() {
		return {
			loggedName : "....",
			companyName : "....",
			notification : {
				count : 0,
				items: {}
			}
		}
	},
	methods: {
		limitText(str, length, ending) {
			if (length == null) {
				length = 100;
			}
			if (ending == null) {
				ending = '...';
			}
			if (str.length > length) {
				return str.substring(0, length - ending.length) + ending;
			} else {
				return str;
			}
		},
		getHumanDate(date) {
            return moment(date).fromNow();
        },
		logOut() {
			AuthService.logout();
			location.reload();
		},
		async checkDetails() {
            await APIService.callAPI('notifications/get', {}, function(response){
				this.notification.count = response.data.unread_count;
				this.notification.items = response.data.items;
			}.bind(this));

		},
		async checkUser() {
			await APIService.callAPI('auth/details', {}, function(response) {
				localStorage.setItem('displayName', response.data.data.displayName);
				localStorage.setItem('displayEmail', response.data.data.logged_email);
				localStorage.setItem('displayCompanyName', response.data.data.companyDetails.companyName);
				localStorage.setItem('su', response.data.data.userAccess);
				localStorage.setItem('CID', response.data.data.companyDetails.id);
				if(!response.status){
					toast.error(response.message);
				}
			}.bind(this));
			this.loggedName  = localStorage.getItem('displayName');
		}
	},
	mounted() {
		this.checkDetails();
		this.checkUser();
		
	},
};
</script>