<template>
	<div class="login-box">
		<div class="login-logo">
			<a href="../../index2.html"><b>Notisafe</b></a>
		</div>
		<div class="card">
			<div class="card-body login-card-body">
				<div class="input-group mb-3">
					<input type="email" @keydown.enter="formSubmit"  class="form-control" placeholder="Email" v-model="user.username">
					<div class="input-group-append">
						<div class="input-group-text">
							<span class="fas fa-envelope"></span>
						</div>
					</div>
				</div>
				<div class="input-group mb-3">
					<input :type="showPassword? 'text':'password'" @keydown.enter="formSubmit" class="form-control" placeholder="Password" v-model="user.password">
					<div class="input-group-append" @click.prevent="showhidePassword">
						<div class="input-group-text"  v-if="showPassword">
							<i class="fas fa-eye"></i>
						</div>
						<div class="input-group-text"  v-if="!showPassword">
							<i class="fas fa-eye-slash"></i>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-4">
						<button @click.prevent="formSubmit" class="btn btn-primary btn-block">Sign In</button>
					</div>
				</div>
				<p class="mb-1">
					<router-link to="/reset-password">I forgot my password</router-link>
				</p>
			</div>
		</div>
	</div>
</template>


<script>
	import APIService from "../../services/api.service"
	import { toast } from 'vue3-toastify';
	export default {
		data() {
			return {
				showPassword : false,
				user : {
					username : "",
					password : ""
				},
				bodyFormData : new FormData()
			}
		},
		mounted() {

		},  
		methods: {
			async formSubmit() {
				this.$parent.setLoading(true);
				await APIService.callAPI('authenticate',  this.user, function(response){
					if(response.status){
						//login succss
						toast.success("Logged in successfully");
						localStorage.setItem('token', response.data.token);
						localStorage.setItem('refresh_token', response.data.refresh_token);
						this.checkUser();
					} else {
						//login failed
						toast.error(response.message);
						this.$parent.setLoading(false);
					}
				}.bind(this));
			}, 
			async checkUser() {
				await APIService.callAPI('auth/details', {}, function(response){
					if(response.status != 200){
						toast.error(response.message);
					} else {
						location.reload();
					}
				}.bind(this));
				
			},
			showhidePassword() {
				this.showPassword = !this.showPassword;
			}
		},
	};
</script>