<template>
    <body class="hold-transition login-page">
		<Preloader v-if="isLoading"></Preloader>
        <div class="login-box">
            <div class="login-logo">
                <a href="../../index2.html"><b>{{ page.label }}</b></a>
            </div>
            <div class="card">
                <div class="card-body login-card-body">
                    <div class="input-group mb-3" v-if="!emailSent">
                        <input type="email" @keydown.enter="formSubmit"  class="form-control" placeholder="Email" v-model="user.email">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="!emailSent">
                        <div class="col-4">
                            <button @click.prevent="formSubmit" class="btn btn-primary btn-block">Reset</button>
                        </div>
                    </div>
                    <p class="mb-1">
                        <span v-if="emailSent">We have sent the recovery password to <b>{{ user.email }}</b><br /><br />This email may take a few minutes to arrive in your inbox.</span>
                        <router-link to="/login">Back to login</router-link>
                    </p>
                </div>
            </div>
        </div>
	</body>
</template>

<script>

import APIService from "../../services/api.service"
import Preloader from "../../template/Preloader.vue"
import { toast } from 'vue3-toastify';

export default {
	name: "App",
	data() {
		return {
            page : {
                label : "Reset Password"
            },
			isLoading: false,
            emailSent : false,
            bodyFormData : new FormData(),
            user : {
                email: ""
            }
		}
	},
	components: {
		Preloader
	},
	methods: {
        async formSubmit() {
            this.isLoading = true;
			this.bodyFormData.append("email", this.user.email);
            await APIService.callAPI('reset-password', this.bodyFormData, function(response){
                if(response.data.success){
                    this.page.label = "Check your email"
                    toast.success(response.data.message);
                    this.isLoading = false;
                    this.emailSent = true;
                } else {
                    toast.error(response.data.message);
                    this.isLoading = false;
                }
            }.bind(this));
        }
    },
	mounted() {}
};
</script>
