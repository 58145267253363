<template>
	<body class="hold-transition sidebar-mini layout-fixed">
		<div class="wrapper">
			<Navbar></Navbar>
			<Sidebar></Sidebar>
			<div class="content-wrapper">
				<ContentHeader :pagetitle="PageTitle" :navigation="navigation"></ContentHeader>
				<section class="content">
					<div class="container-fluid" >
						<Loading v-if="!isLoaded"></Loading>
                        <Components v-if="isLoaded" :data="data" ref="component"></Components>
					</div>
				</section>
			</div>
			<Footer></Footer>
			<ControlSidebar></ControlSidebar>
		</div>
	</body>
</template>
<script>

import ContentHeader from "../../../template/ContentHeader.vue"
import Navbar from "../../../template/Navbar.vue";
import Sidebar from "../../../template/Sidebar.vue";
import Footer from "../../../template/Footer.vue";
import ControlSidebar from "../../../template/ControlSidebar.vue"
import Loading from "../../../template/Loading.vue"
import Components from "./Components.vue"


export default {
	components: {
		ContentHeader,
        Components,
		Navbar,
		Sidebar,
		Footer,
		Loading,
		ControlSidebar
	},
    data() {
        return {
            isLoaded: false,
			navigation : {
				"Home" : "/",
				"Blueprint" : "#",
				"Detailed Locations" : "#",
			},
			data: {},
			PageTitle: "Detailed Locations",
            bodyFormData : new FormData()
        }
    },
	methods: {
		
	},
	mounted() {
        this.isLoaded = true;
    }
};
</script>