<template>
    <div class="card">
        <div class="card-header">
            <div class="card-tools">
                <div class="input-group input-group-sm">
                    <input type="text" @keydown.enter="search"  v-model="keyword" class="form-control float-right" placeholder="Search">
                    <div class="input-group-append">
                        <button type="submit" @click.prevent="search" class="btn btn-default">
                            <i class="fas fa-search"></i>
                        </button>
                        <button v-if="showReset" type="submit" @click.prevent="clear" class="btn btn-danger" >
                            <i class="fas fa-sync-alt"></i>
                        </button>
                        <button v-if="su" type="submit" @click.prevent="Add" class="btn btn-info">
                            <i class="fas fa-plus"></i>
                        </button>
                        <button v-if="su" type="submit" @click.prevent="Export" class="btn btn-primary">
                            <i class="fas fa-download"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body table-responsive p-0">
            <table class="table table-bordered text-nowrap">
                <thead>
                    <tr>
                        <th v-for="(header, i) in data.header" :key="i">
                            {{ header }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(user, i) in data.data" :key="i">
                        <td>{{ user.first_name }} {{ user.last_name}}</td>
                        <td>{{ user.email }}</td>

                        <td>
                            <div class="checkbox checbox-switch switch-primary" v-if="loggedEmail != user.email && su">
                                <label>
                                    <input type="checkbox" name="" :checked="user.Status"  @change="onStatusChange(user.id, $event)"/>
                                    <span></span>                                        
                                </label>
                             </div>
                        </td>
                        <td>
                            <div class="btn-group btn-group-sm">
                                <button type="submit" v-if="loggedEmail != user.email && su" @click.prevent="Edit(user)" class="btn btn-info">
                                    <i class="fas fa-pen"></i>
                                </button>
                                <button type="submit" @click.prevent="View(user)"  class="btn btn-primary">
                                    <i class="far fa-eye"></i>
                                </button>
                                <button  type="submit" v-if="loggedEmail != user.email && su" @click.prevent="Remove(user)" class="btn btn-danger" >
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div class="card-footer clearfix">
            {{ data.paginate.display_result }} 
            <div class="card-tools">
                <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page > 1" @click.prevent="paginate(1)">First</button>
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page > 1" @click.prevent="paginate(data.paginate.current_page - 1)">Prev</button>
                    </div>
                    <select class="custom-select" @change="paginate($event.target.value)">
                        <option :selected="page == data.paginate.current_page" v-for="(page, i) in data.paginate.pages" :key="i"   :value="page">
                            Page {{ page }}
                        </option>
                    </select>
                    <div class="input-group-append">
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page < data.paginate.total_page" @click.prevent="paginate(data.paginate.current_page + 1)">Next</button>
                        <button  type="submit" class="btn btn-default" v-if="data.paginate.current_page < data.paginate.total_page" @click.prevent="paginate(data.paginate.total_page)">Last</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="ModalShow" class="modal show" role="dialog"  style="display: block;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ ModalLabel }}</h5>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="required">Employee No</label>
                        <input :disabled="ModalReadOnly" type="text" :class="v$.employee.employee_no.$error? 'form-control is-invalid' : 'form-control' " placeholder="Employee No." v-model="employee.employee_no">
                        <span class="error invalid-feedback" v-if="v$.employee.employee_no.$error">{{ v$.employee.employee_no.$errors[0].$message }} </span>
                    </div>
                    <div class="form-group">
                        <label class="required">First Name</label>
                        <input :disabled="ModalReadOnly" type="text" :class="v$.employee.first_name.$error? 'form-control is-invalid' : 'form-control' " placeholder="First Name" v-model="employee.first_name">
                        <span class="error invalid-feedback" v-if="v$.employee.first_name.$error">{{ v$.employee.first_name.$errors[0].$message }} </span>
                    </div>
                    <div class="form-group">
                        <label class="">Middle Name</label>
                        <input :disabled="ModalReadOnly" type="text" class="form-control" placeholder="Middle Name" v-model="employee.middle_name">
                    </div>
                    <div class="form-group">
                        <label class="required">Last Name</label>
                        <input :disabled="ModalReadOnly" type="text" :class="v$.employee.last_name.$error? 'form-control is-invalid' : 'form-control' " placeholder="Last Name" v-model="employee.last_name">
                        <span class="error invalid-feedback" v-if="v$.employee.last_name.$error">{{ v$.employee.last_name.$errors[0].$message }} </span>
                    </div>
                    <div class="form-group">
                        <label class="required">Gender</label>
                        <select v-model="employee.gender"  :class="v$.employee.gender.$error? 'custom-select is-invalid' : 'custom-select' " :disabled="ModalReadOnly" >
                            <option value="m" :selected="employee.gender == 'm'">Male</option>
                            <option value="f" :selected="employee.gender == 'f'">Female</option>
                        </select>
                        <span class="error invalid-feedback" v-if="v$.employee.gender.$error">{{ v$.employee.gender.$errors[0].$message }} </span>
                    </div>
                    <div class="form-group">
                        <label class="required">Email Address</label>
                        <input :disabled="ModalReadOnly" type="email" :class="v$.employee.email.$error? 'form-control is-invalid' : 'form-control' " placeholder="Email Address" v-model="employee.email">
                        <span class="error invalid-feedback" v-if="v$.employee.email.$error">{{ v$.employee.email.$errors[0].$message }} </span>
                    </div>
                    <div class="form-group">
                        <label class="required">Contact No</label>
                        <input :disabled="ModalReadOnly" type="tel" :class="v$.employee.contact.$error? 'form-control is-invalid' : 'form-control' " placeholder="Contact No" v-model="employee.contact">
                        <span class="error invalid-feedback" v-if="v$.employee.contact.$error">{{ v$.employee.contact.$errors[0].$message }} </span>
                    </div>

                    <div class="form-group" v-if="branch.length && su">
                        <label class="required">Access</label>
                        <select class="custom-select" v-model="user.access" :disabled="ModalReadOnly" >
                            <option value="su"  :selected="user.access == 'su'? 'selected' : ''" >Super User</option>
                            <option v-for="(item, i) in branch" :selected="item.branchId == user.access? 'selected': ''" :key="i" :value="item.branchId">
                                {{ item.Branch }}
                            </option>
                        </select>
                        
                    </div>
                    <small>All fields markes with an asterisk (<span class="required"></span> ) are required.</small>
                </div>
                <div class="modal-footer">
                    <button type="button" :disabled="loading"  class="btn btn-secondary" @click.prevent="hideModal" >Close</button>
                    <button type="button" v-if="!ModalReadOnly" :disabled="loading" class="btn btn-primary" @click.prevent="user.id? submitUpdate() : submitAdd()  "> {{ submitButtonLabel }} </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import useValidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { toast } from 'vue3-toastify';
import APIService from "../../../services/api.service"
import Swal from 'sweetalert2'

export default {
    props: ['data'],
    data() {
        return {
            v$: useValidate(),
            ModalLabel: '',
            loading: false,
            submitButtonLabel : 'Save Changes',
            ModalShow: false,
            ModalReadOnly: false,
            showReset: this.data.searched_keyword? true : false,
            keyword : this.data.searched_keyword?? "",
            user_id : null,
            branch: [],
            user : {
                name : '',
                email: '',
                access: ''
            },
            employee: {
                id : null,
                first_name: "",
                middle_name: "",
                last_name: "",
                employee_no: "",
                gender: "",
                email: "",
                contact: "",
                branch: "",
            },
            su : localStorage.getItem('su') == "true"? true : false,
            bodyFormData : new FormData(),
            loggedEmail : localStorage.getItem('displayEmail')
        }
    },
    methods: {
        getHumanDate(date) {
            return moment(date).format('LLL');
        },
        search() {
            if(this.keyword){
                this.$parent.search(this.keyword);
            }
        },
        paginate(page){
            this.$parent.search(this.keyword, page);
        },
        clear() {
            this.keyword = "";
            this.$parent.search(this.keyword);
        },
        showModal() {
            this.ModalShow = true;
        },
        hideModal() {
            this.ModalShow = false;
        },
        Add() {
            this.getBranches();
            this.ModalLabel = "Add New User";
            this.ModalReadOnly = false;
            this.user.email = null;
            this.user.name = null;
            this.user.id = null;
            this.showModal();
        },
        async getBranches() {
            this.branch = [];
            await APIService.callAPI('users/get-branches',  this.bodyFormData, function(response) {
                if(response.data.success){
                    this.branch = response.data.data;
                } 
            }.bind(this));
            
        },
        async View(user) {

            this.employee.employee_no = 'Fetching data..';
            this.employee.first_name = 'Fetching data..';
            this.employee.middle_name = 'Fetching data..';
            this.employee.last_name = 'Fetching data..';
            this.employee.contact = 'Fetching data..';
            this.employee.email = 'Fetching data..';
            this.employee.gender = 'Fetching data..';
            
            this.user.access = null;
            this.ModalLabel = "View User Details";
            this.showModal();
            this.fetchingData();
            this.bodyFormData = new FormData();
			this.bodyFormData.append("user_id", user.id);
            await APIService.callAPI('users/get',  this.bodyFormData, function(response) {
                if(response.data.success){
                    this.setData(response.data.data);
                    this.ModalReadOnly = true;
                } else {
                    toast.error(response.data.message);
                    this.hideModal();
                }
            }.bind(this));
        },
        async Edit(user) {
            this.user.access = null;
            this.ModalLabel = "Update User Details";
            this.showModal();
            this.fetchingData();
            this.bodyFormData = new FormData();
			this.bodyFormData.append("user_id", user.id);
            await APIService.callAPI('users/get',  this.bodyFormData, function(result) {
                if(result.data.success){
                    this.setData(result.data.data);
                    this.ModalReadOnly = false;
                } else {
                    toast.error(result.data.message);
                    this.hideModal();
                }

            }.bind(this));
        },
        async Remove(user) {
            Swal.fire({
                title: 'Remove User',
                html: `Are you sure you want to remove <b>${user.displayName}</b>?`,
                showCancelButton: true,
                confirmButtonText: 'Yes, remove user',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let formdata = new FormData();
                    formdata.append("user_id", user.id);
                    await this.$parent.remove(formdata);
                }
            })
        },
        fetchingData() {
            this.ModalReadOnly = true;
            this.user.email = "fetching data...";
            this.user.name = "fetching data...";
        }, 
        setData(user) {
            this.employee.employee_no = user.employeeNumber;
            this.employee.first_name = user.firstName;
            this.employee.middle_name = user.middleName;
            this.employee.last_name = user.lastName;
            this.employee.contact = user.contactNumber;
            this.employee.email = user.email;
            this.employee.gender =user.gender;
            this.user.id = user.id;
            this.user.access = user.branch?? 'su';
        },
        async onStatusChange(id, e) {
            let status = 0;
            if(e.target.checked){
                status = 1;
            }

            this.bodyFormData = new FormData();
            this.bodyFormData.append("user_id", id);
            this.bodyFormData.append("status", status);
            await APIService.callAPI('users/set-status', this.bodyFormData, function(response){
                if(response.data.success){
                    toast.success(response.data.message);
                }
            }.bind(this));
        },
        async submitAdd() {
            this.loading = true;
            this.submitButtonLabel = "Loading..";
            this.v$.$validate()
            if (!this.v$.$error) {
                this.bodyFormData = new FormData();
                this.bodyFormData.append("employee_id", this.employee.id);
                this.bodyFormData.append("employee_no", this.employee.employee_no);
                this.bodyFormData.append("first_name", this.employee.first_name);
                this.bodyFormData.append("last_name", this.employee.last_name);
                this.bodyFormData.append("middle_name", this.employee.middle_name);
                this.bodyFormData.append("gender", this.employee.gender);
                this.bodyFormData.append("email", this.employee.email);
                this.bodyFormData.append("contact", this.employee.contact);
                this.bodyFormData.append("access", this.user.access); 

                await this.$parent.add(this.bodyFormData);
                this.loading = false;
                this.submitButtonLabel = "Save Changes";
            } else {
                toast.error('There are items that require your attention.')
                this.loading = false;
                this.submitButtonLabel = "Save Changes";
            }
        },
        async submitUpdate() {
            this.loading = true;
            this.submitButtonLabel = "Loading..";
            this.v$.$validate()
            if (!this.v$.$error) {
                this.bodyFormData = new FormData();
                this.bodyFormData.append("employee_id", this.employee.id);
                this.bodyFormData.append("employee_no", this.employee.employee_no);
                this.bodyFormData.append("first_name", this.employee.first_name);
                this.bodyFormData.append("last_name", this.employee.last_name);
                this.bodyFormData.append("middle_name", this.employee.middle_name);
                this.bodyFormData.append("gender", this.employee.gender);
                this.bodyFormData.append("email", this.employee.email);
                this.bodyFormData.append("contact", this.employee.contact);
                this.bodyFormData.append("id", this.user.id);
                this.bodyFormData.append("access", this.user.access);
                await this.$parent.update(this.bodyFormData);
                this.loading = false;
                this.submitButtonLabel = "Save Changes";
            } else {
                toast.error('There are items that require your attention.')
                this.loading = false;
                this.submitButtonLabel = "Save Changes";
            }
        },
        async Export() {
            let instance = toast.info("Please wait while we generate your download");
            await APIService.callAPI('users/export-all',  {}, function(response){
                if(response.data.success){
                    window.open(response.data.file, '_blank');
                    instance.dimsiss();
                } else {
                    toast.error(response.data.message);
                }
            }.bind(this));
        }
    },
    mounted() {
        this.getBranches();
    },
    validations() {
        return {
            employee: {
                first_name: { required },
                last_name: { required },
                employee_no: { required },
                gender: { required },
                email: { required, email },
                contact: { required }
            }
        }
    }
}
</script>