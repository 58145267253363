<template>
    <div class="card">
        <div class="card-header">
            <div class="card-tools">
                <div class="input-group input-group-sm">
                    <input type="text" @keydown.enter="search"  v-model="keyword" class="form-control float-right" placeholder="Search">
                    <div class="input-group-append">
                        <button type="submit" @click.prevent="search" class="btn btn-default">
                            <i class="fas fa-search"></i>
                        </button>
                        <button v-if="keyword" type="submit" @click.prevent="clear" class="btn btn-danger" >
                            <i class="fas fa-sync-alt"></i>
                        </button>
                        <button type="submit" @click.prevent="Add" class="btn btn-info">
                            <i class="fas fa-plus"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body table-responsive p-0">
            <Loading v-if="isLoading"></Loading>
            <table class="table table-bordered text-nowrap">
                <thead>
                    <tr>
                        <th v-for="(header, i) in data.header" :key="i">
                            {{ header }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in data.data" :key="i">
                        <td><span v-if="item.Branch != undefined">{{ item.Branch }} /</span> {{ item.location_name }}</td>
                        <td>{{ item.Area }}</td>
                        <td>
                            <img @click="Mappreview(item)" :src="item.LocationMap" alt="" srcset="" width="50" height="50">
                        </td>
                        <td>
                            <div class="checkbox checbox-switch switch-primary">
                                <label>
                                    <input type="checkbox" name="" :checked="item.Status"  @change="onStatusChange(item.id, $event)"/>
                                    <span></span>                                        
                                </label>
                             </div>
                        </td>
                        <td>
                            <div class="btn-group btn-group-sm">
                                <button type="submit" @click.prevent="Edit(item)" class="btn btn-info">
                                    <i class="fas fa-pen"></i>
                                </button>
                                <button  type="submit" class="btn btn-danger" @click.prevent="Remove(item)"  >
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div class="card-footer clearfix"  v-if="data.data">
            {{ data.paginate.display_result?? "" }} 
            <div class="card-tools">
                <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page > 1" @click.prevent="paginate(1)">First</button>
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page > 1" @click.prevent="paginate(data.paginate.current_page - 1)">Prev</button>
                    </div>
                    <select class="custom-select" @change="paginate($event.target.value)">
                        <option :selected="page == data.paginate.current_page" v-for="(page, i) in data.paginate.pages" :key="i"   :value="page">
                            Page {{ page }}
                        </option>
                    </select>
                    <div class="input-group-append">
                        <button type="submit" class="btn btn-default" v-if="data.paginate.current_page < data.paginate.total_page" @click.prevent="paginate(data.paginate.current_page + 1)">Next</button>
                        <button  type="submit" class="btn btn-default" v-if="data.paginate.current_page < data.paginate.total_page" @click.prevent="paginate(data.paginate.total_page)">Last</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="ModalShow" class="modal show" role="dialog"  style="display: block;">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ ModalLabel }}</h5>
                </div>
                <div class="modal-body">
                    <div class="form-group" v-if="locations.length">
                        <label class="required">Location</label>
                        <select class="custom-select" v-model="input.location" :disabled="ModalReadOnly" :class="v$.input.location.$error? 'form-control is-invalid' : 'form-control' ">
                            <option v-for="(item, i) in locations" :selected="item.id == input.location? 'selected': ''" :key="i" :value="item.id">
                                <span v-if="item.Branch != undefined">{{ item.Branch.branch}} /</span> {{ item.Location }}
                            </option>
                        </select>
                        
                    </div>

                    <div class="form-group">
                        <label class="required">Area</label>
                        <input :disabled="ModalReadOnly" type="text" :class="v$.input.area.$error? 'form-control is-invalid' : 'form-control' " placeholder="Area" v-model="input.area">
                        <span class="error invalid-feedback" v-if="v$.input.area.$error">{{ v$.input.area.$errors[0].$message }} </span>
                    </div>


                    <div class="form-group">
                        <label :class="input.note? '' : 'required'">Upload Map</label>
                        <input :disabled="ModalReadOnly" type="file"  accept="image/*" ref="file" :class="v$.input.map.$error? 'form-control is-invalid' : 'form-control' " placeholder="Upload Logo" @change="UploadMap($event)">
                        <i v-if="input.note">{{ input.note }}</i>
                        <span class="error invalid-feedback" v-if="v$.input.map.$error">{{ v$.input.map.$errors[0].$message }} </span>
                    </div>

                    <small>All fields markes with an asterisk (<span class="required"></span> ) are required.</small>
                </div>
                <div class="modal-footer">
                    <button type="button" :disabled="isLoading"  class="btn btn-secondary" @click.prevent="hideModal" >Close</button>
                    <button type="button" v-if="!ModalReadOnly" :disabled="isLoading" class="btn btn-primary" @click.prevent="input.id? submitUpdate() : submitAdd()  "> {{ submitButtonLabel }} </button>
                </div>
            </div>
        </div>
    </div>
    


</template>

<script>
    import useValidate from '@vuelidate/core'
    import Loading from "../../../template/Loading.vue"
    import APIService from "../../../services/api.service"
    import { required, helpers } from '@vuelidate/validators'
    import { toast } from 'vue3-toastify';
    import Swal from 'sweetalert2'
    import {preview} from 'vue3-image-preview';

    export default {
        components: {
            Loading
        },
        data() {
            return {
                v$: useValidate(),
                isLoading: false,
                data: [],
                locations: [],
                keyword: "",
                page: 1,
                ModalLabel: "New Area",
                submitButtonLabel: "Save Changes",
                ModalReadOnly: true,
                ModalShow: false,
                input: {
                    id: null,
                    location: "",
                    area : null,
                    map : null,
                    note: null
                }
            }
        },
        methods: {
            search() {
                this.data = [];
                this.getList();
            },
            paginate(page){
                this.page = page;
            },
            Add() {
                this.v$.$reset();
                this.getLocations();
                this.input.id = null;
                this.ModalReadOnly = false;
                this.ModalShow = true;
                this.ModalLabel = 'New Area';
                this.submitButtonLabel = "Save Changes";
            },
            hideModal() {
                this.ModalShow = false;
            },
            clear() {
                this.v$.$reset();
                this.input.id = null;
                this.input.location = null;
                this.input.area = null;
                this.keyword = "";
                this.getList();
            },
            async UploadMap(event) {
                this.input.map = event.target.files[0];
            },
            async getLocations() {
                this.isLoading = true;
                this.branch = [];
                await APIService.callAPI('blueprint/get-locations',  this.bodyFormData, function(response) {
                    if(response.data.success){
                        console.log(response.data.data);
                        this.locations = response.data.data;
                        this.isLoading = false;
                    } 
                }.bind(this));
                
            },

            async Edit(item) {
                this.clear();
                this.getLocations();
                this.input.id = item.id;
                this.ModalReadOnly = true;
                this.ModalShow = true;
                this.ModalLabel = 'Modify Area';
                this.input.location = 'Fetching data..';
                this.input.area = 'Fetching data..';

                this.bodyFormData = new FormData();
                this.bodyFormData.append("id", this.input.id);
                await APIService.callAPI('blueprint/area-get-details', this.bodyFormData, function(response){
					if(response.data.success){
                        this.dataLoaded = true;
                        this.ModalReadOnly = false;
                        this.submitButtonLabel = "Save Changes";

                        this.input.location = response.data.data.Location.id;
                        this.input.area = response.data.data.Area;
                        this.input.map = response.data.data.LocationMap;
                        this.input.note = "Leave blank if no changes on map image"
                        
					}
				}.bind(this));
            },
            async getList(){
                this.isLoading = true;
                this.data = [];
                this.bodyFormData = new FormData();
                this.bodyFormData.append("search", this.keyword);
                this.bodyFormData.append("page", this.page);
                await APIService.callAPI('blueprint/get-area-list', this.bodyFormData, function(result) {
                    this.data = result.data;
                    this.isLoading = false;
                }.bind(this));
            },  
            async submitUpdate() {
                this.isLoading = true;
                this.submitButtonLabel = "Updating..";
                this.v$.$validate()
                if (!this.v$.$error) {
                    this.bodyFormData = new FormData();
                    this.bodyFormData.append("id", this.input.id);
                    this.bodyFormData.append("location_id", this.input.location);
                    this.bodyFormData.append("area", this.input.area);
                    this.bodyFormData.append("map", this.input.map);
                    await APIService.callAPI('blueprint/update-area',  this.bodyFormData, function(response){
                        if(response.data.success){
                            toast.success(response.data.message);
                            this.clear();
                            this.hideModal();
                            this.getList();
                        } else {
                            toast.error(response.data.message);
                            this.isLoading = false;
                            this.submitButtonLabel = "Save Changes";
                        }
                    }.bind(this));

                } else {
                    toast.error('There are items that require your attention.')
                    this.isLoading = false;
                    this.submitButtonLabel = "Save Changes";
                }
            },
            async submitAdd() {
                this.isLoading = true;
                this.submitButtonLabel = "Saving..";
                this.v$.$validate()
                if (!this.v$.$error) {
                    this.bodyFormData = new FormData();
                    this.bodyFormData.append("location_id", this.input.location);
                    this.bodyFormData.append("area", this.input.area);
                    this.bodyFormData.append("map", this.input.map);
                    await APIService.callAPI('blueprint/add-area',  this.bodyFormData, function(response){
                        if(response.data.success){
                            toast.success(response.data.message);
                            this.clear();
                            this.hideModal();
                            this.getList();
                        } else {
                            console.log("A");
                            toast.error(response.data.message);
                            this.isLoading = false;
                            this.submitButtonLabel = "Save Changes";
                        }
                    }.bind(this));
                } else {
                    toast.error('There are items that require your attention.')
                    this.isLoading = false;
                    this.submitButtonLabel = "Save Changes";
                }
            },
            async onStatusChange(id, e) {
                let status = 0;
                if(e.target.checked){
                    status = 1;
                }

                this.bodyFormData = new FormData();
                this.bodyFormData.append("id", id);
                this.bodyFormData.append("status", status);
                await APIService.callAPI('blueprint/area-set-status', this.bodyFormData, function(response){
					if(response.data.success){
						toast.success(response.data.message);
					}
				}.bind(this));
            },
            
            async Remove(item) {
                let message =`Are you sure you want to remove <b>${item.location_name}/${item.Area}</b>?`;
                if(item.Branch){
                    message =`Are you sure you want to remove <b>${item.Branch }/${item.location_name}/${item.Area}</b>?`;
                }
                Swal.fire({
                    title: 'Remove Location',
                    html: message,
                    showCancelButton: true,
                    confirmButtonText: 'Yes, remove area',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.bodyFormData = new FormData();
                        this.bodyFormData.append("id", item.id);
                        await APIService.callAPI('blueprint/remove-area', this.bodyFormData, function(response){
                            if(response.data.success){
                                toast.success(response.data.message);
                                this.clear();
                                this.hideModal();
                                this.getList();
                            }
                        }.bind(this));
                    }
                })
            },
            Mappreview: function(item) {
                preview({
                    images: item.LocationMap
                });
            }
        },
        mounted() {
            this.getList();
        },
        validations() {
            if(this.input.note){
                return {
                    input: {
                        location: { required },
                        area: { required },
                        map: {  },
                    },
                }
            }
            return {
                input: {
                    location: { required },
                    area: { required },
                    map: {
                        FileRules: helpers.withMessage("File is required", () => {
                            return this.input.map? true: false;
                        }),
                        FileType: helpers.withMessage("File type should be Image", () => {
                            return this.input.map.type == "image/png" || this.input.map.type == "image/jpeg" || this.input.map.type == "image/jpg"  ? true: false;
                        })
                    }
                },
            }
            
        }
    }
</script>