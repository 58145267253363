<template>
	<!-- Main Sidebar Container -->
	<aside class="main-sidebar sidebar-dark-primary elevation-4">
		<!-- Brand Logo -->
		<span class="brand-link">
			<img v-if="companyLogo" :src="companyLogo" alt="AdminLTE Logo" class="brand-image img-circle elevation-3">
			<span class="brand-text font-weight-light">{{companyName}}</span>
			<a href="#" class="float-right text-light d-block d-lg-none" data-widget="pushmenu">
				<span class="fas fa-times text-white"></span>
			</a>
		</span>

		<!-- Sidebar -->
		<div class="sidebar">
			<!-- Sidebar Menu -->
			<nav class="mt-2">
				<ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
					<li class="nav-item">
						<router-link to="/" class="nav-link">
							<p>Home</p>
						</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/employees" class="nav-link">
							<p>Employees</p>
						</router-link>
					</li>
					<li class="nav-item" v-if="su">
						<router-link to="/maintenance/details" class="nav-link">
							<p>Details</p>
						</router-link>
					</li>
					<li class="nav-item" v-if="su">
						<a href="#" class="nav-link">
							<p>
								Layout
								<i class="fas fa-angle-left right"></i>
							</p>
						</a>
						<ul class="nav nav-treeview" style="display: none;">
							<li class="nav-item">
								<router-link to="/maintenance/locations" class="nav-link">
									<p>Locations</p>
								</router-link>
								<router-link to="/maintenance/areas" class="nav-link">
									<p>Areas</p>
								</router-link>
								<router-link to="/maintenance/detailed-locations" class="nav-link">
									<p>Detailed Locations</p>
								</router-link>
							</li>
						</ul>

					</li>
					<li class="nav-item" v-show="su" v-for="module in maintenance" :key="module.id">
						<a href="#" class="nav-link">
							<p>
								{{ module.module.module }} 
								<i class="fas fa-angle-left right"></i>
							</p>
						</a>

						<ul class="nav nav-treeview" style="display: none;">
							<li class="nav-item" v-for="field in module.fields"  :key="field.id">
								<router-link :to="{ name: 'MaintenanceModule', query: { module_id: module.module.id, field_id: field.id }}" class="nav-link">
									<p>{{ field.label }}</p>
								</router-link>
							</li>
						</ul>
					</li>
				</ul>
			</nav>
			<!-- /.sidebar-menu -->
		</div>
		<!-- /.sidebar -->
	</aside>
</template>

<script>
import APIService from "../services/api.service"
export default {
	data() {
		return {
			companyName: "NotiSafe",
			companyLogo: null,
            su : localStorage.getItem('su') == "true"? true : false,
			maintenance: []
		}
	},
	methods: {
		async getModuleMaintenance(){
			await APIService.callAPI('modules/list',  this.bodyFormData, function(response) {
                if(response.data.success){
					this.maintenance = response.data.modules;
                } 
            }.bind(this));
		},
		async checkUser() {
			await APIService.callAPI('auth/details', {}, function(response) {

				if(response.data.data.companyDetails.companyName){
					this.companyName = response.data.data.companyDetails.companyName;
				}
				if(response.data.data.companyLogo){
					this.companyLogo = response.data.data.companyLogo;
				}
				this.su = response.data.data.userAccess;
				localStorage.setItem('displayCompanyName', response.data.data.companyDetails.companyName);
				localStorage.setItem('su', response.data.data.userAccess);
			}.bind(this));
		}
	},
	mounted() {
		this.checkUser();
		this.getModuleMaintenance();
	},
};

</script>