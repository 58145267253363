const token = localStorage.getItem('token');

const initialState = token
  ? { status: { loggedIn: true }}
  : { status: { loggedIn: false }};

class AuthService {

    check(){
        //ccheck token
        return initialState;
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('displayEmail');
        localStorage.removeItem('displayName');
        localStorage.removeItem('displayCompanyName');
        localStorage.removeItem('CID');
    }

}

export default new AuthService();