<template>
<div class="content-header">
	<div class="container-fluid">
		<div class="row mb-2">
			<div class="col-sm-6">
				<h1 class="m-0">{{ pagetitle }}</h1>
			</div>
			<div class="col-sm-6">
				<ol class="breadcrumb float-sm-right">
					<li v-for="(header, i) in navigation" :key="i" class="breadcrumb-item">
						<a v-if="header != '#'" :href="header">{{  i  }}</a>
						<span v-if="header == '#'" :href="header">{{  i  }}</span>
					</li>
				</ol>
			</div>
		</div>
	</div>
</div>
</template>

<script>
export default {
props: ["pagetitle", "navigation"]
};
</script>