<template>
	<section class="content">
		<div class="container-fluid">
			<h3>Employees</h3>
			<div class="row">
				<div class="col-lg-3 col-6">
					<div class="small-box bg-info">
						<div class="inner">
							<h3>{{this.details.active_employees}}</h3>
							<p>Active Employees</p>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-6">
					<div class="small-box bg-danger">
						<div class="inner">
							<h3>{{this.details.inactive_employees}}</h3>
							<p>Inactive Employees</p>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-6">
					<div class="small-box bg-green">
						<div class="inner">
							<h3>{{this.details.all_employees}}</h3>
							<p>All Employees</p>
						</div>
					</div>
				</div>
			</div>

			<h3>Haztrack</h3>
			<div class="row">
				<div class="col-lg-3 col-6">
					<div class="small-box bg-secondary">
						<div class="inner">
							<h3>{{this.obs.open_obs}}</h3>
							<p>Open Observations</p>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-6">
					<div class="small-box bg-green">
						<div class="inner">
							<h3>{{this.obs.closed_obs}}</h3>
							<p>Closed Observations</p>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-6">
					<div class="small-box bg-info">
						<div class="inner">
							<h3>{{this.obs.total_obs}}</h3>
							<p>Total Observations</p>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-3 col-6">
					<div class="small-box bg-success">
						<div class="inner">
							<h3>{{this.obs.low_risk}}</h3>
							<p>Low Risk</p>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-6">
					<div class="small-box bg-warning">
						<div class="inner">
							<h3>{{this.obs.medium_risk}}</h3>
							<p>Medium Risk</p>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-6">
					<div class="small-box bg-danger">
						<div class="inner">
							<h3>{{this.obs.high_risk}}</h3>
							<p>High Risk</p>
						</div>
					</div>
				</div>
			</div>
		</div>    
	</section>
</template>
<script>
import APIService from "../../services/api.service"

export default {
	data() {
		return {
			bodyFormData : null,
			details: {
				active_employees : 0,
				inactive_employees : null,
				all_employees : null,
			},
			obs : {
				open_obs : null,
				closed_obs : null,
				total_obs : null,
				high_risk : null,
				medium_risk : null,
				low_risk : null,
			}
		}
	},
	methods: {
		async getDashboard() {
			this.bodyFormData = new FormData();
			await APIService.callAPI('dashboard', this.bodyFormData, function(response){
				if(response.data.success){
					let data = response.data;
					this.details.active_employees = data.details.active_employees ?? 0;
					this.details.inactive_employees = data.details.inactive_employees ?? 0;
					this.details.all_employees = data.details.total_employees ?? 0;

					this.obs.open_obs = data.OBS.numbers[0].open_obs ?? 0;
					this.obs.closed_obs = data.OBS.numbers[0].closed_obs ?? 0;
					this.obs.total_obs = data.OBS.numbers[0].total_obs ?? 0;

					this.obs.high_risk = data.OBS.numbers[0].high_risk ?? 0;
					this.obs.medium_risk = data.OBS.numbers[0].medium_risk ?? 0;
					this.obs.low_risk = data.OBS.numbers[0].low_risk ?? 0;
				}
			}.bind(this));
		}
	},
	mounted() {
		this.getDashboard();
	},
};
</script>