import { createRouter, createWebHashHistory } from 'vue-router' 
import AuthService from "../services/auth.service"

import Home from '../pages/home/Home.vue'
import Login from '../pages/login/Login.vue'
import ChangePassword from '../pages/settings/ChangePassword.vue'
import MaintenancePage from '../pages/maintenance/users/Page.vue'
import DetailsPage from '../pages/maintenance/details/Page.vue'
import MaintenanceModule from '../pages/maintenance/modules/Page.vue'
import NotificationPage from '../pages/notification/List.vue'
import ResetPassword from '../pages/reset_password/ResetPassword.vue'
import Employee from '../pages/employee/Page.vue'
import Locations from '../pages/maintenance/locations/Page.vue'
import Areas from '../pages/maintenance/areas/Page.vue'
import DetailedLocation from '../pages/maintenance/detailed_location/Page.vue'
import ReportsDashboard from '../pages/reports/dashboard/Page.vue'

const routes = [
    { name: 'Login', path: '/login', component: Login, meta: { requiresAuth: false }},
    { path: '/home', component: Home, meta: { requiresAuth: true }},
    { path: '/', component: Home, meta: { requiresAuth: true }},
    { path: '/change-password', component: ChangePassword, meta: { requiresAuth: true }},
    { path: '/maintenance/users', component: MaintenancePage, meta: { requiresAuth: true }},
    { path: '/maintenance/details', component: DetailsPage, meta: { requiresAuth: true }},
    { path: '/maintenance/locations', component: Locations, meta: { requiresAuth: true }},
    { path: '/maintenance/areas', component: Areas, meta: { requiresAuth: true }},
    { path: '/maintenance/detailed-locations', component: DetailedLocation, meta: { requiresAuth: true }},
    { name: 'MaintenanceModule', path: '/maintenance/module', component: MaintenanceModule, meta: { requiresAuth: true }, params: true},
    { path: '/notifications', component: NotificationPage, meta: { requiresAuth: true }},
    { path: '/employees', component: Employee, meta: { requiresAuth: true }},
    { name: 'ResetPassword', path: '/reset-password', component: ResetPassword, meta: { requiresAuth: false }},
    { name: 'Reports', path: '/reports', component: ReportsDashboard, meta: { requiresAuth: true }, params: true},
]

const redirectToLogin = route => {
    if (route.name != 'Login') {
        if(route.name != 'ResetPassword' ){
            return { name: 'Login', replace: true};
        }
    }
};
const router = createRouter({
    history: createWebHashHistory(),
    routes, 
})

router.beforeEach((to) => {
    if(!AuthService.check().status.loggedIn){
        localStorage.removeItem('token');
        return redirectToLogin(to);
    } 

    return true;
    
})

export default router;